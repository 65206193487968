
import Styles from "../styles/styles";
import { useState } from "react";
import url from "./url";
import cookie from "react-cookies";
import useFetch from "../customHook/useFetch";
import { PlusCircle } from "react-bootstrap-icons";
import { IoIosMail, IoIosPerson, IoIosPhonePortrait } from "react-icons/io";



const User =()=>{

    const token = cookie.load('id');
    //console.log(token);
    //console.log(access);
    const [trigger, setTrigger] = useState(false);
    
    const{data, isPending, error} = useFetch(url(`user/userdetails/${token}`),"GET",null,trigger);
data && console.log(data)
    return(
<div className="card" style={Styles.card}>
    
            <div style={Styles.wrapper}>
               {//} <div style={{height:100, width:100, display:"flex", justifyContent:"center", alignItems:"center",   borderRadius:50, backgroundColor:"dimgray"}}>
                   // <PlusCircle  color="white" size={30}/></div>
                }
                    
         {data && <div>   <h3>{data.firstname.toUpperCase()} {data.lastname.toUpperCase()}</h3>
            <IoIosMail color="#AF0000" size={30}/><p style={Styles.smallBoldText}> {data.email}</p>
           <IoIosPhonePortrait color="#AF0000"  size={30}/> <p style={Styles.smallBoldText}>{data.phone}</p> 
           <IoIosPerson color="#AF0000"  size={30}/><p style={Styles.smallBoldText}>{data.account}</p>
           </div>}
        
            </div>
      </div>
    )
}

export default User;