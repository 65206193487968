import React, { useState } from "react";
import useFetch from '../customHook/useFetch';
import url from "../components/url";
import { Container,Row,Col,Table,Dropdown,Form } from "react-bootstrap";
import Styles from "../styles/styles";


const CandidateNumber=({dateId})=>{

 


    const [trigger, setTrigger]= useState(true);
    const {data, isPending, error} = useFetch(url(`user/participantsbydates/${dateId}`),"GET", null, trigger);
    const Participants = data;
    //change corses to trainig date in front and backend
    return(
        
          

        <p style={{border: 'none',  borderWidth:0}}>
            { Participants && Participants.length}</p>

  
)


    
    
}

export default CandidateNumber;