import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal, Form} from 'react-bootstrap';
import {useState} from 'react';
import Styles from '../styles/styles';
import { useSelector, useDispatch } from 'react-redux';



const TripSelect=({options,label})=>{

const dispatch = useDispatch();
const actionFunc = (payload)=>{
    const action={type:"ADD_TRIP", payload:payload};
    return action;
}

    return(
        <div>
            <Form onSubmit={(e)=>e.preventDefault(e)}>
<Form.Label style={Styles.label}>{label}</Form.Label>
        <Form.Select 
        
        onChange={(e)=>
             dispatch(actionFunc({medic:e.currentTarget.value}))
        } aria-label="select">     
{options.map((option,i)=>{ 
    return(
        <option key={i} value={option.value}>{option.label}</option>
        );
})
}

</Form.Select>
</Form>
</div>
    )
}

export default TripSelect;