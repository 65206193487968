import { createStore } from "redux"; 
import useFetch from "../customHook/useFetch";
const initialState = {

    cart:[],

  trigger: false,

user:{
    userID:'',
    firstname:'',
    status:'',
},
    trip:{
        origin:'',
        destination:'',
        medic:''

    },

    courseRegistration:{
        test:"this is a test text",
  firstName:"",
  lastName:"",
  email:"",
  phone:"",
  course:"",
  date:"",
  venue:"",
  password:"",
  repassword:"",
    },

    tempInput:["hello","apple",],

    history:[],

     
}
    

const reducer = (state = initialState, action)=>{


switch (action.type){

    case "CHANGE_TRIGGER":
        return Object.assign({},state,{
           trigger: !state.trigger
            });

    case "ADD_CART":
        return Object.assign({},state,{
         cart:state.cart.concat(action.payload)
         });

         case "ADD_QUANTITY":
            return Array.assign({},state,{
            cart: state.cart[action.payload.index].quantity=action.payload.quantity
             });

    case "ADD_USER":
        return Object.assign({},state,{
         user:{  userID:(action.payload.userID)?action.payload.userID:state.user.userID},
         firstname:{  firstname:(action.payload.firstname)?action.payload.firstname:state.user.firstname},
         status:{ status:(action.payload.status)?action.payload.status:state.user.status},
         });

    case"ADD_INPUT":
    return Object.assign({},state,{
        tempInput: state.tempInput.concat(action.payload)
     });

   case "ADD_FORM":
       return Object.assign({},state,{
           courseRegistration:{
               firstName:(action.payload.firstName)?action.payload.firstName:state.courseRegistration.firstName,
               lastName:(action.payload.lastName)?action.payload.lastName:state.courseRegistration.lastName,
               email:(action.payload.email)?action.payload.email:state.courseRegistration.email,
               phone:(action.payload.phone)?action.payload.phone:state.courseRegistration.phone,
               course:(action.payload.course)?action.payload.course:state.courseRegistration.course,
               date:(action.payload.date)?action.payload.date:state.courseRegistration.date,
               venue:(action.payload.venue)?action.payload.venue:state.courseRegistration.venue,
               password:(action.payload.password)?action.payload.password:state.courseRegistration.password,
               repassword:(action.payload.repassword)?action.payload.repassword:state.courseRegistration.repassword,
           }
       })  
    
    
 case "ADD_TRIP":
    return Object.assign({},state,{
     trip:{  origin:(action.payload.origin)?action.payload.origin:state.trip.origin,
        destination:(action.payload.destination)?action.payload.destination:state.trip.destination,
        medic:(action.payload.medic)?action.payload.medic:state.trip.medic,}
     });

     case"ADD_HISTORY":
     return Object.assign({},state,{
        history: state.history.concat(action.payload)
     })

default:return state;
}

/*
if (action.type === 'ADD_TRIP'){
    return Object.assign({},state,{
       trip: action.payload
    })
    }
    
    if (action.type === 'ADD_HISTORY'){
    return Object.assign({},state,{
       history: state.history.concat(action.payload)
    })
}

if (action.type === 'ADD_INPUT'){
    return {tempInput: action.payload, ...state} 
    
}

if(action.type ==='RESET'){
    return initialState;
}
   */
}


const store = createStore(reducer);

export default store;