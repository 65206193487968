const CoursDetailsObj ={
    bls:{
        title:'Basic Life Support (BLS)',
        body:[
            {
            title:"What's BLS",
        body:'The AHA’s BLS course trains participants to promptly recognize several life-threatening emergencies, give high-quality chest compressions, deliver appropriate ventilations and provide early use of an AED. Reflects science and education from the American Heart Association Guidelines Update for CPR and Emergency Cardiovascular Care (ECC).'
        }, 
        {
            title:"Who should take this course?",
        body:"The AHA’s BLS Course is designed for healthcare professionals and other personnel who need to know how to perform CPR and other basic cardiovascular life support skills in a wide variety of in-facility and prehospital settings."}
    ], 
    objectives: [
        'High-quality CPR for adults, children, and infants',
'The AHA Chain of Survival, specifically the BLS components',
'Important early use of an AED',
'Effective ventilations using a barrier device',
'Importance of teams in multirescuer resuscitation and performance as an effective team member during multirescuer CPR',
'Relief of foreign-body airway obstruction (choking) for adults and infants',
    ],
    duration:'6 hours',

    pictures:['bls']
    },
    acls:{
        title:'Advanced Cardiovascular Life Support',
        body:'rdhgfkjhvjhvl,kbjkjbkjlbkjbkjb jkbjkbkjbkjbk bjkjbnkjbnkljnk '
    }
}

export default CoursDetailsObj;