import React from "react";
import { Container } from "react-bootstrap";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import Styles from "../styles/styles";


const SuccessPage = ({})=>{
//
const convertDate =(rawDate)=> new Date(rawDate).toDateString();
const {state} = useLocation();
console.log(state);

    return(
        <Container>
            <div style={Styles.centerWrapper} className='d-flex justify-content-center align-items-center flex-column'>
<h1>
    SUCCESSFUL REGISTRATION
</h1>

<p><BsFillCheckCircleFill size={90} color="green"/></p>
<b>Course:</b><h5>{state.data.IDcourses.course.item}</h5>
<b>Date:</b><h5>{ convertDate(state.data.IDcourses.date)}</h5>
<b>Address:</b><h5>{state.data.IDcourses.venue.location}</h5>
<b>Name:</b><h5>{state.data.IDUser.firstname.toUpperCase() +" "+state.data.IDUser.lastname.toUpperCase()}</h5>
<b>Payment reference:</b><h5>{state.data.IDRef}</h5>
<p>A payment confirmation has been sent to your email address</p>
<p>For more information call us on: <a href="tel:08146821431">08146821431</a></p>

</div>
</Container>

    )


}

export default SuccessPage;