import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal, Form} from 'react-bootstrap';
import {useState} from 'react';
import Header from '../components/Header';
import CustomInput from './CustomInput';
import Styles from '../styles/styles';

import Paystack from './Paystack';
import useFetch from '../customHook/useFetch';
import url from './url';
import DatePicker from 'react-date-picker';
import { IoIosMail, IoIosPhonePortrait } from 'react-icons/io';
import numberToWords from '../functions/numberToWords';

import { CircleLoader, FadeLoader, PropagateLoader } from 'react-spinners';


const Registration=({date, color, userId, dateData})=>{
    //data removed
    console.log("dateData")
   console.log(dateData)
    const person = new Object();
    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
      };
     //calculate total cost

     //ebookcost initially set at null
    const [ebookCost, setEbookCost] = useState(0);
    const [purchase, setPurchase]= useState([])

    //total cost calculates cost of both the book & the course
    const totalCost = (ebookCost, courseCost) =>{
        var costInKobo = courseCost + ebookCost
return costInKobo
    }
  //total cost ends here  


  
  //retreve user info
  const trigger ="";
const{data, isPending, error} = useFetch(url(`user/userdetails/${userId}`),"get",null,trigger);
data && console.log(data)

    //body sent to server
    if(isPending === false){ 
        console.log("user")
        console.log(data)
    const body = {
     
        form: data,
        
        trainingDateData:dateData,
        amount:totalCost(ebookCost,dateData.course.value),
        cart:[dateData.course.item],
        ebookAdded:false,
        purchaseRecord:[data.firstname+ " "+data.lastname+ " paid ₦"
        +(dateData.course.value/100).toLocaleString()+" for "+dateData.course.item
        +" scheduled on "+ new Date(dateData.date),...purchase]
        };

        console.log(body.purchaseRecord)
//error might arise from item not being specific
        const addItem = (item)=>{
            setEbookCost(item.value);
            setPurchase([item.item+" was added at ₦"+(item.value/100).toLocaleString(),...purchase])
            body.ebookAdded=true
         //   body.items.push(item.item)
            //console.log(body)
        }

        const removeItem = (item) =>{
            setEbookCost(0)
          setPurchase(purchase.filter((record)=>{
                return record != item.item+" was added at ₦"+(item.value/100).toLocaleString()
            }))  
            body.ebookAdded=false
      //   body.items= body.items.filter((item)=>{
//return body.items !== item.item
     //    })

        // console.log(body)
        
        }
        var courseFeeToNaira = dateData.course.value/100
var ebookFeeToNaira = ebookCost/100



   return(
        <div style={Styles.background} >
           
            
            
       <h3 style={Styles.boldText}>Enroll</h3>
        <div style={Styles.centerWrapper}> 
        <div style={{backgroundColor:color, height:"100%", width:"100%",padding:10, color:"#363636"}}>  
        <h4>{dateData.course.item.toUpperCase()}</h4>
        <h5>{date}</h5>
        <h6>{dateData.venue.location}</h6>
        </div>
        </div> 
        {//<p>Please fill the form below</p>
}
      
        <div style={Styles.centerWrapper}> 
        <div>
        {data && <h5>{data.firstname.toUpperCase()} {data.lastname.toUpperCase()}</h5>}
        <IoIosMail size={30}/>
        {data && <p style={Styles.smallBoldText}>{data.email} </p>}
        <IoIosPhonePortrait size={30}/>
        {data && <p style={Styles.smallBoldText}>{data.phone} </p>}
        </div></div>
        <div style={Styles.centerWrapper}> 
        
            <input onChange={()=>{(ebookCost===0)?addItem(dateData.ebook):removeItem(dateData.ebook)}} style={{borderColor:"red", color:"maroon"}} type="checkbox" />
        <label style={Styles.smallBoldText}> Add Course Manual Ebook</label>
        <p style={Styles.smallBoldText}> Please deselect this option if you already have a provider manual</p>
        
        
      <p style={Styles.smallBoldText}>Course fee: ₦{ courseFeeToNaira.toLocaleString()}</p>
      {ebookCost !== 0 && <p>E-book fee: {ebookFeeToNaira.toLocaleString()}</p>}
      <h4>Total: {(totalCost(ebookCost,dateData.course.value)/100).toLocaleString()}</h4>
</div>
      
{data && <Paystack body={body}/>}
        
        </div>
    );
}else{
   
    return(
    <div style={{width:"100%", justifyContent:'center', alignItems:'center'}}>
            <FadeLoader size={150} color="#C70039" cssOverride={override} loading= {true} speedMultiplier={1}/>
            </div> 
)}

}

export default Registration;