import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal, Form} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Header from '../components/Header';
import CustomInput from './CustomInput';
import Styles from '../styles/styles';
import TripSelect from './TripSelect';
import useFetch from '../customHook/useFetch';
import Autocomplete from "react-google-autocomplete";
import store from '../store/index';
import PlaceInput from './PlaceInput';
import { connect } from 'react-redux';
import { useSelector,useDispatch } from 'react-redux';
import url from './url';


const TripDetails =({coverage})=>{

    const options=[
        {label:"Self provided", value:"none"},
        {label:"Paramedic", value:"paramedic"},
    {label:"Nurse", value:"nurse"},
    {label:"Doctor", value:"doctor"},
    {label:"Anasthetist", value:"Anasthetist"},
]
 /*const [origin, setOrigin]= useState("");
 const [destination, setDestination]= useState("");
 const [medic, setMedic]= useState("");
 const [wait, setWait]= useState("");
const [trigger, setTrigger] = useState(null);
*/
const [wait, setWait]= useState("");
const [cost, setCost]= useState("");
const trip = useSelector(state=>state.trip)
const dispatch = useDispatch();

const [trigger, setTrigger] = useState(false);
const {origin, destination, medic} = useSelector((state)=>state.trip)
const body= {origin,destination,medic,wait, coverage:coverage};

const{data, isPending, error} = useFetch(url("ambulance/cost"),"post",body,trigger)

//dispatch({type: 'RESET' });

const cover = coverage;

const handleSubmit=(e)=>{
    if(cover===coverage){
e.preventDefault();
    console.log(body);
    (trigger===false)?setTrigger(true):setTrigger(false);
   }
//setCost(data);
//console.log(cost);
   
}
    return(
        <div>
            {coverage === "intrastate" && <h3>State Trip</h3>}
            {coverage === "intrastate" && <p>This calculation targets trips within a state e.g Lagos</p>}
            {coverage === "interstate" && <h3>Inter State Trip</h3>}
            {coverage === "interstate" && <p>This calculation targets trips outside a state e.g Lagos to Abuja</p>}
            {data && console.log(data)}
            <PlaceInput label="Origin"/>
            <PlaceInput label="Destination"/>
{/*<CustomInput label="Origin" placeholder="Where does the trip start" onChange={e =>setOrigin(e.target.value)}/>
        <CustomInput label="Destination" placeholder="Where is our destination" onChange={e =>setDestination(e.target.value)}/>*/}
        <TripSelect options={options} label="Accompanying medic" />
        <CustomInput label="Wait time (hours)" placeholder="Will there be any wait time" type="number" onChange={e =>setWait(e.target.value)}/>
        <Button className="action-key" style={Styles.actionKey} variant="custom" onClick={(e)=>handleSubmit(e)}>Calculate</Button>

        </div>
    )
}



export default TripDetails;