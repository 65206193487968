import React from "react";
import { useDispatch } from "react-redux";
import cookie  from "react-cookies";
import url from "./url";
import Styles from "../styles/styles";
import fetchFunc from "../customHook/fetchFunc";
import { useNavigate } from "react-router-dom";


const AddToCart =({id,status})=>{
    const dispatch = useDispatch();
    const navigate =useNavigate();

    const user = cookie.load('id');
   
   
   const sendToCart = (cartContent)=>{
       fetchFunc(url('user/updatecart'),'POST',cartContent).then((res)=>{
           dispatch({type:'CHANGE_TRIGGER'}) 
       })
      
   }
    return(
        <div>
        {(status!=='loggedIn')&& <button style={Styles.button}
        onClick={()=>{ navigate('/login')}}>Add to cart</button>}
       {(status==='loggedIn')&& <button className="action-key" 
  variant="custom" 
  style={Styles.button}
 onClick={()=>{
   sendToCart({user:user, id: id, quantity:1, 
     })
       }}>Add to cart</button>}</div>
    )
}

export default AddToCart;