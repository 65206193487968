import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import Header from '../components/Header';
import ProductCard from '../components/ProductCard';


const Products=({status})=>{

    
    

    return(
        <div>
            

       <ProductCard status={status}/>

        </div>
    );

}

export default Products;