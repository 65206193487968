import React, { useEffect } from "react";
import { useState } from "react";
import url from "./url";


const StreamedImage = ({name})=> {
    
const [img, setImg] = useState(false);

useEffect(async()=>{
await fetch(url(`pictures/${name}`), {
         
         method: "GET", 
         redirect: "follow",
           credentials: 'include',
       })
   .then((response)=>{
      // console.log(response)
       return response.blob()
   }).then((res)=>{
        //  console.log(res)
         // const reader = new FileReader()
         // return reader.readAsDataURL(response)
         
      //   const url = URL.createObjectURL(res)
        // URL.revokeObjectURL(res)
       //  window.open(url)
        const url = URL.createObjectURL(res)
        setImg(url);
      //  console.log(url)
     
       
       }).catch((err)=>{
           console.log(err)
       });    

},[])

      

return(
    <div>
        {img && <img height='100%' width='100%' src={img}/>}
    </div>
)

   }
   
   export default StreamedImage;