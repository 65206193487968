import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal, Form} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Styles from '../styles/styles';
import Autocomplete from "react-google-autocomplete";
// import store from '../store/index';
import { useStore, useSelector, useDispatch } from'react-redux';
import { connect } from 'react-redux';
import store from '../store';


const PlaceInput =({label,type,placeholder,helpText, onChange})=>{

  const dispatch = useDispatch();
  const origin = useSelector(state=>state.trip.origin);
const description = label;

    return(
<div>
 
<p>{origin.formatted_address}</p>
<Form onSubmit={(e)=>e.preventDefault()}>
  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
    <Form.Label style={Styles.label}>{label}</Form.Label>
    <Autocomplete className="autocomplete" style={Styles.input}
            apiKey={"AIzaSyAtwtf-mfEyTeEjAq2FxH3ByADohA-azvg"}
            placeholder={placeholder}
            onPlaceSelected={(place)=>{
              const action={type:"ADD_TRIP", payload:(description==="Origin")?{origin:place}:{destination:place}}
              try{
             dispatch(action)
             console.log(store.getState().trip);
                } catch(err){console.log(err)}
            }}
            options={{
              types: [("geocode","establishment")],
              componentRestrictions: { country: "ng" },
            }}
            />
    
    <Form.Text id="passwordHelpBlock" muted>
    {helpText}
  </Form.Text>
  </Form.Group>
 
</Form>
</div>
    );
}




/*const mapStateToProps = state =>{
  return {tempInput: state.tempInput}
}
const mapDispatchToProps = dispatch =>{
  return {dispatch};
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceInput);*/
export default PlaceInput;