import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Key } from "react-bootstrap-icons";
import { AiFillDelete } from "react-icons/ai";
import { IoCloseCircle } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import numberToWords from "../functions/numberToWords";
import Styles from "../styles/styles";
import url from "./url";
import useFetch from "../customHook/useFetch";
import cookie from 'react-cookies';
import fetchFunc from "../customHook/fetchFunc";
import { useNavigate } from "react-router-dom";

// this is the details of the cart found at the header
const Cart =({status,cart})=>{
   const navigate= useNavigate();
    const dispatch = useDispatch()
    let carts = [
        {id:1, item:"Manual Difibrillator", price:300000000, location:"Within Lagos", img:require('../images/marquette.jpeg'),engage:"Buy", quantity:1},
        {id:2, item:"Adult CPR manikin", price:22500000, location:"Within Lagos", img:require('../images/cprmanikin.jpg'),engage:"Buy",quantity:1},
        {id:3, item:"Adult CPR manikin", price:500000, location:"Within Lagos", img:require('../images/cprmanikin.jpg'),engage:"Buy",quantity:1}
        
    ]

//const[trigger, setTrigger] = useState(true)
//duplicate fetch in cart and cart button, try removing one
const trigger = useSelector(state=>state.trigger)
    const user = cookie.load('id');
    const{data, isPending, error} = useFetch(url(`user/cart/${user}`),'GET', "",trigger)

  //  isPending && console.log('ispending')
  // data && console.log(data)
  // error && console.log(error)
  

    //const cart = useSelector(state=>state.cart)
    
  //  console.log(trigger)


   
    let i=0


    var subtotal = useRef(0);
    subtotal.current = 0;


/*const update =(e)=>{
setTrigger(!trigger)
}*/

  
const addQuantity =(e,i)=>{
    fetchFunc(url('user/updatecartquantity'),'POST',{product:data[i].product._id, user:user, quantity: parseInt(e.target.value)}).then((response)=>{
        dispatch({type: 'CHANGE_TRIGGER'})
    })
    
//cart[i].quantity = e.target.value
   // setProducts(cart)
   // console.log(products[i].item+ ':' +products[i].quantity)
   // console.log(products)
    }

    const cartDelete =(e,i)=>{
        fetchFunc(url('user/cartdelete'),'POST',{product:data[i].product._id, user:user}).then((response)=>{
             dispatch({type: 'CHANGE_TRIGGER'})
        })
       
    }
    
    return(
        <div style={{position:'relative'}}>
           
{status==='loggedIn' &&
typeof data == "object" && data.map((cart, index)=>{
        
      

subtotal.current= subtotal.current+(cart.product.value*cart.quantity);
//console.log(subtotal.current)
        return(
            <div key={index} style={{fontSize:12, display:'flex', borderBottom:'solid',
             borderWidth:1, minHeight:40, padding:5
             }}>
<div  style={{padding:10, overflowWrap:'break-word',}}><p>{cart.product.item}</p></div>
<div style={{padding:10, overflowWrap:'anywhere',width:140 }}><p style={{}}>{numberToWords(cart.product.value)}</p></div>
<div style={{padding:10, overflowWrap:'break-word'}}><div>
    <p style={{}}>Quantity</p>
    <div style={{display:"flex"}}> {
    cart.quantity==1 && <AiFillDelete onClick={(e)=>{cartDelete(e,index)}} color="#8F0000" size={20}/>}
    <input style={{width:40,marginLeft:10 }}type={'number'} 
    placeholder={cart.quantity} min={1} max={cart.product.quantity}
//value={cart.quantity}
    onChange={(e)=>{addQuantity(e,index)}}/></div>
    </div></div>
                </div>
        )
        i++
    })
   }<div>
   
    <p><b>7.5% VAT</b>:{numberToWords(subtotal.current*0.075)}</p>
    <p><b>Total</b>:{numberToWords((subtotal.current*0.075)+subtotal.current)}</p>
   </div>
   <div style={{display:'inline'}}>
   
    <button style={Styles.button} onClick={(e)=>{navigate('/user/checkout')}} >Checkout</button>
   </div>
        </div>
    )

    subtotal.current = 0
}

export default Cart;