
import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal, Form} from 'react-bootstrap';
import {useState} from 'react';
import Styles from '../styles/styles';
import { useSelector, useDispatch } from 'react-redux';

const CustomInput =({label,type,placeholder,helpText, onChange, setValue, value, name
  // actionType, actionValue
  })=>{

  //const actionBody = new Object();
  //const dispatch = useDispatch();
  //const actionFunc = (payload)=>{
   // const action={type:actionType, payload:payload};
  //  return action;
//}
const [error, setError] = useState("");
    return(
<div style={{maxWidth:500}}>
<Form onSubmit={(e)=>e.preventDefault(e)}>
  <Form.Group className="mb-3"  controlId="exampleForm.ControlInput1">
    <Form.Label style={Styles.label}>{label}</Form.Label>
  
    <Form.Control type={type} placeholder={placeholder} name={name} onChange={(e)=>{
      if(type=="email" ){
        
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ( re.test(e.currentTarget.value) ) {
console.log("email is valid");
setValue({
  ...value,
  [e.target.name] : e.target.value})
//actionBody[actionValue]=e.currentTarget.value;
    //         dispatch(actionFunc(actionBody));
          // this is a valid email address
          // call setState({email: email}) to update the email
          // or update the data in redux store.
          setError("")
          console.log(value)
      }
      else {
        console.log("email is not valid");
        setError("This is not a valid email");
          // invalid email, maybe show an error to the user.
      }
      }
      if(type !="email" && e.target.value !==""){
     // actionBody[actionValue]=e.currentTarget.value;
        //     dispatch(actionFunc(actionBody));
        setValue({
          ...value,
          [e.target.name] : e.target.value})
          setError("")
        console.log(value)
             }else if(type !="email"){setError("This is a required information")}
            
            }
        }/>
    <Form.Text id="passwordHelpBlock" muted>
    {helpText}
    {error}
  </Form.Text>
  </Form.Group>
 
</Form>
</div>
    );
}

export default CustomInput;