import React, { useState } from 'react';
  import logo from '../images/deadend.png';
  import { usePaystackPayment } from 'react-paystack';
  //import './App.css';
  import Header from './Header';
  import Styles from '../styles/styles';
  import {Button,} from 'react-bootstrap';
  import useFetch from '../customHook/useFetch';
import CustomModal from './CustomModal';
 // import { useSelector } from 'react-redux';
 import { Navigate } from 'react-router-dom';
 import { useNavigate } from 'react-router-dom'; 
import url from './url';
import fetchFunc from '../customHook/fetchFunc';

  
  

  const PaystackHookExample = () => {
    
      return (
        <div>
            
        </div>
      );
  };
  
 function Paystack({body}) {
    const navigate = useNavigate();
const trigger ="";

const enroll =()=>{
  let guestPromise = new Promise((resolve, reject)=>{

    //if theres no id in the form body, check for email in database
    if(body.form._id =="none"){
fetchFunc(url(`checkuseremail/${body.form.email}`),"GET",body.form,true).then((result)=>{

  if( result != null){
  
       resolve( result)
      }  
      else if( result == null){
       // console.log('no account with that email found',3)
       //if result is null, create a guest account
 fetchFunc(url("auth/guestsignup"),"POST",body.form,true).then((result)=>{
 // console.log(result)
  resolve(result)
          
        })
      }        
            })

    } else{
    //  console.log('logged in  enroll', body.form)
      resolve(body.form)}

  })

  return guestPromise
}


  
    const config = {
        reference: (new Date()).getTime().toString(),
        email: body.form.email,
        amount: body.amount,
        publicKey: 'pk_live_286060c33553ac4d153991806872f6658583b4ce',
    };
    const initializePayment = usePaystackPayment(config);
    // you can call this function anything
    const onSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
    //use the enroll function, then create a payment record with the result
 try{
   
       enroll().then((result)=>{
     //   console.log(result)
     // if(body.form._id!='none'){
        fetch(url("payments"),
      {
        mode:'cors',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    method:"post",
    body:JSON.stringify({
        reference:reference,
       training_date:body.trainingDateData._id,
      user:result,
      total:body.amount,
   cart:body.cart,
   ebook_added: body.ebookAdded,
   purchase_record: body.purchaseRecord
    })
}).then(res=>{
    if(!res.ok){
        throw Error('could not fetch resource: registration incomplete');
    }
    return res.json();
})
.then(data=>{
  //  console.log(data);
   // <Navigate to="/ambulance"/>
   if(data.error){
    console.log(data.error)
   }else{ navigate("/success", {state:{ data:data}, replace:true});}
 
    
}).catch(err=>{console.log(err)});
    //  });
    
//} 
     
      // }else{console.log('An error occured')}
      
})
        }catch(err){
       console.log(err) 
      }

    };
  
    // you can call this function anything
    const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log('closed')
    }

    return (
      <div className="App">
       
        {/*<img src={logo} className="App-logo" alt="logo" />*/}
        <Button style={Styles.button}
         onClick={() => {
                initializePayment(onSuccess, onClose)
            }}>Pay</Button>
      </div>
    );
  }
  
  export default Paystack;