import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useState } from "react";
import { IoCheckmarkCircleSharp, IoImages } from "react-icons/io5";
import PictureBank from "../components/PictureBank";
import StreamedImage from "../components/StreamedImage";
import url from "../components/url";
import Styles from "../styles/styles";
import fetchFunc from '../customHook/fetchFunc';
import UploadImage from "../components/UploadImage";
import { Dropzone, FileItem } from "@dropzone-ui/react";
import { ScaleLoader } from "react-spinners";

const AddProduct = ({account, user})=>{

const [productName, setProductName] = useState('');
const [category, setCategory] = useState('');
const [transact, setTransact] = useState('');
const [productDetails, setProductDetails] = useState('');
const [shippingDays, setShippingDays] = useState(0);
const [productPrice, setProductPrice] = useState(0);
const [uploadedBy, setUploadedBy] = useState('')
const [response, setResponse] = useState(null)
const [detailPage, setDetailPage] = useState('block');
const [successPage, setSuccessPage] = useState('none');
const [quantity, setQuantity] = useState(0)
const [loading, setLoading]= useState(false)


const product ={productName,category, transact, productDetails,
shippingDays, productPrice, uploadedBy}


    

    

    const [files, setFiles] = React.useState([]);
    const updateFiles = (incommingFiles) => {
      setFiles(incommingFiles);
      console.log(files)
    };

      const removeFile = (id) => {
        setFiles(files.filter((x) => x.id !== id));
      };


      const addProduct =async(e)=>{
        e.preventDefault()
        
        let formData = new FormData();   
        let i = 0;
        while(i<files.length){
            formData.append('pictures', files[i].file);
            i++
        }

        formData.append('productname', productName);
        formData.append('category', category);
        formData.append('transact', transact);
        formData.append('productdetails', productDetails);
        formData.append('shippingdays', shippingDays);
        formData.append('productprice', productPrice*100);
        formData.append('quantity', quantity);

       await fetch(url('admin/addproduct'),{
          
            method: 'POST',
            redirect: "follow",
            credentials: 'include',
            body: formData
        }).then((res)=>{
           return res.json();
        }).then((response)=>{
            console.log(response)
            setResponse(response)
            setLoading(false)
        })

      }
 

    console.log(user)
    
    return(
        <div style={Styles.flexContainer}>
           
            
         
          { !response && <div style={Styles.centerWrapper}>
                <h5 style={Styles.boldText}>NEW PRODUCT</h5>
            <div style={{margin:10}}>
                <label style={Styles.smallBoldText}>Product Name</label>
                <input onChange={(e)=>{setProductName(e.target.value)  }} 
                name='productname' required
                type='text' style={Styles.input2}/>
            </div>
            <div style={{margin:10}}>
                <label style={Styles.smallBoldText}>Category</label>
                <select type='' style={Styles.input2}
                name='category' required
                 onChange={(e)=>{setCategory(e.target.value)  }} >
                    <option value='none'>Choose category</option>
                    <option value='training equipment'>Training equipment</option>
                    <option value='airway devices'>Airway devices</option>
                    <option value='bleeding control'>Bleeding control</option>
                    
                 </select>
            </div>
            <div style={{margin:10}}>
                <label style={Styles.smallBoldText}>Transact</label>
                <select type='' style={Styles.input2}
                name='transact' required
                 onChange={(e)=>{setTransact(e.target.value)  }} >
                    <option value='None'>Choose transaction</option>
                    <option value='Buy'>Sale</option>
                    <option value='Hire'>Rental</option>
                    <option value='Enroll'>Enroll</option>
                    
                 </select>
            </div>

            <div style={{margin:10, display:'block'}}>
                <label style={Styles.smallBoldText}>Product Details</label>
                <input type='textbox' style={Styles.input2}
                name='productdetail' required
                onChange={(e)=>{setProductDetails(e.target.value)  }} />
            </div>
           

           <div style={{margin:10, display:"block"}}>
                <label style={Styles.smallBoldText}>Shipping days</label>
                <input type='number' name="shippingdays"
                required
                onChange={(e)=>{setShippingDays(e.target.value)  }} style={Styles.input2}/>
            </div>

            <div style={{margin:10, display:"block"}}>
                <label style={Styles.smallBoldText}>product Price (₦)</label>
                <input name="price" type='number' 
                required
                onChange={(e)=>{setProductPrice(e.target.value)  }} style={Styles.input2}/>
            </div>

            <div style={{margin:10, display:"block"}}>
                <label style={Styles.smallBoldText}>Quantity</label>
                <input type='number' name="quantity"
                required
                onChange={(e)=>{setQuantity(e.target.value)  }} style={Styles.input2}/>
            </div>

<Dropzone onChange={updateFiles} value={files}>
      {files.map((file) => (
        <FileItem {...file} onDelete={removeFile} key={file.id} preview />
      ))}
    </Dropzone>

            <div style={{margin:10, display:"block"}}>
                {!loading && <button onClick={(e)=>{
                    setLoading(true)
                    addProduct(e)}} style={Styles.button}>Add</button>}
                    {loading && <button onClick={(e)=>{
                    setLoading(true)
                    addProduct(e)}} style={Styles.button}><ScaleLoader
                     color={"#E92C2C"} loading={true} 
                     cssOverride={Styles.override} size={150} /></button>}
            </div>

            
        </div>
        
}
       
        {response && <div style={Styles.centerWrapper}>

<p style={Styles.boldText}>{response.msg.toUpperCase()}</p>
<h3>{response.response.item}</h3>
<p style={Styles.smallBoldText}>{response.response.description}</p>
<p style={Styles.smallBoldText}>{response.response.category}</p>
<IoCheckmarkCircleSharp size={100} color='green'/>
<PictureBank images={response.response.images}/>

            </div>}
          
        
        </div>
    )
}

export default AddProduct;