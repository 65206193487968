import React from "react";
import Styles from "../styles/styles";
import { BsTextParagraph } from "react-icons/bs";
import { IoArrowForwardCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const CoursDetails = ({course})=>{

    const navigate = useNavigate();


    return(
        <div style={{padding:10, width:'100%', justifyContent:'center', display:'flex',
        alignItems:'center', }}>
            <div style={Styles.articleWrapper}>
            <img style={{width:'100%', height:'100%'}}src={require(`../images/${course.pictures[0]}.jpg`)} />
                <div style={{padding:15}}>
                  <h3>{course.title}</h3>  
                  {course.body.map((paragraph)=>{
                    return(
                        <div>
                    <h4>{paragraph.title}</h4>
                    <p>{paragraph.body}</p>  
                    </div>  
                    )

                  })}

                  <h4>What you'll learn</h4>
                  {course.objectives.map((objective)=>{

                    return(
                        <div style={{display:'flex'}}>
                        <IoArrowForwardCircle color="#A00101" size={30}/>    <p style={{marginLeft:10}}>{objective}</p>
                        </div>
                    )
                  })}

<h4>Duration</h4>
<p>{course.duration}</p>

<div style={{width:'100%', display:'flex', justifyContent:'flex-end'}}>
    <button className='' onClick={()=>navigate('/course_date')} style={{color:'red', backgroundColor:'transparent', border:'none'}}>Find Our Courses</button>
</div>

                  
                </div>
              
            </div>
            

        </div>
    )
}

export default CoursDetails;