const InstructorObj ={
    courses:[
        {
            title: 'BLS Instructor', 
            description: 'American heart association Basic Life Support Instructor Course',
            criterias:[
                //'Register for 2 courses or more',
                'Have a current BLS provider certification',
                'Get an instructor potential designation',
                'Be willing to teach AHA classes',
    
            ],
            cost:[
                {title:'course fee', cost: 200000},
                {title:'online essential', cost: 70000},
                {title:'course manual', cost: 70000},
            ],
    candidatesMin:5,
    candidatesMax:25
        },
        {
            title: 'ACLS Instructor', 
            description: 'American heart association Advanced Support Life Support Instructor Course',
            criterias:[
                //'Register for 2 courses or more',
                'Have a current ACLS provider certification',
                'Get an instructor potential designation',
                'Be willing to teach AHA classes',
    
            ],
            cost:[
                {title:'course fee', cost: 300000},
                {title:'online essential', cost: 70000},
                {title:'course manual', cost: 90000},
            ],
            candidatesMin:26,
    candidatesMax:50
        },
    
        {
            title: 'PALS Instructor', 
            description: 'American heart association Pediatric Advanced Life Support Instructor Course',
            criterias:[
                //'Register for 2 courses or more',
                'Have a current PALS provider certification',
                'Get an instructor potential designation',
                'Be willing to teach AHA classes',
    
            ],
            cost:[
                {title:'course fee', cost: 350000},
                {title:'online essential', cost: 70000},
                {title:'course manual', cost: 90000},
            ],
            candidatesMin:51,
    candidatesMax:Infinity
        },
    ]
    }
    
    export default InstructorObj;