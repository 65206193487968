import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import Login from "../components/Login";
import Signup from "../components/Signup";
import url from "../components/url";
import Styles from "../styles/styles";


const AppLogin =({status, setStatus, setAccount, setUser})=>{
    const [loginTab, setLoginTab] = useState("true")
    const [signupTab, setSignupTab] = useState("none")
    let navigate = useNavigate();
if(status==="loggedIn"){navigate("/clientarea/")}

    return(
        <div>
          <div style={Styles.flexContainer}>
            <button style={Styles.framelessButton}
            onMouseEnter={(e)=>e.currentTarget.style.color = "red"}
            onMouseLeave={(e)=>e.currentTarget.style.color = "black"}
          onClick={(e)=>{
           
            setLoginTab("block")
        setSignupTab("none")}}>Login</button>

<h3>|</h3>

        <button style={Styles.framelessButton}
         onMouseEnter={(e)=>e.currentTarget.style.color = "red"}
         onMouseLeave={(e)=>e.currentTarget.style.color = "black"}
        onClick={(e)=>{setLoginTab("none")
        setSignupTab("block")
        e.currentTarget.style.color = "red"
        }}>Sign up</button></div>


        
      <div style={{display:loginTab}}  title="Login">
        <Login redirect="/user/dashboard" status={status} setStatus={setStatus} setAccount={setAccount} setUser={setUser}/>
      </div>
      <div style={{display:signupTab}}  title="Create Account">
       <Signup  redirect="/" status={status} setStatus={setStatus} setAccount={setAccount} setUser={setUser} />
      </div>
      
    </div>

            
            
        
    )
}

export default AppLogin;