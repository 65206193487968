import React, { useEffect, useState } from 'react';
  import logo from '../images/deadend.png';
  import { usePaystackPayment } from 'react-paystack';
  //import './App.css';
  import Header from './Header';
  import Styles from '../styles/styles';
  import {Button,} from 'react-bootstrap';
  import useFetch from '../customHook/useFetch';
import CustomModal from './CustomModal';
 // import { useSelector } from 'react-redux';
 import { Navigate } from 'react-router-dom';
 import { useNavigate } from 'react-router-dom'; 
 import { useDispatch } from 'react-redux';
import url from './url';

  
  
  function PaystackCart({body, disabled}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
const trigger ="";
   // const{data, isPending, error} = useFetch("https://2d48-41-217-95-179.ngrok.io/","POST",body,trigger);
 
    const config = {
        reference: (new Date()).getTime().toString(),
        email: body.user.email,
        amount: body.total,
        publicKey: 'pk_live_286060c33553ac4d153991806872f6658583b4ce',
    };
    const initializePayment = usePaystackPayment(config);
    // you can call this function anything
    const onSuccess = (reference) => {
      // Implementation for whatever you want to do with reference and after success call.
     // console.log(reference);
      
      fetch(url("cartpayments"),
      {
        mode:'cors',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    method:"post",
    body:JSON.stringify({
        reference:reference,
      user:body.user._id,
      total:body.total,
   cart:body.cart,
   shippingAddress:body.shippingAddress,
    })
}).then(res=>{
    if(!res.ok){
        throw Error('could not fetch resource: registration incomplete');
    }
    return res.json();
})
.then(data=>{
   // console.log(data);
   // <Navigate to="/ambulance"/>
   dispatch({type:'CHANGE_TRIGGER'}) 
  navigate("/successfulpurchase", {state:{ data:data}, replace:true});
    
}).catch(err=>{console.log(err)});
      

    };
  
    // you can call this function anything
    const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
     // console.log('closed')
    }

    return (
      <div className="App">
       
        {/*<img src={logo} className="App-logo" alt="logo" />*/}
        <Button disabled={disabled} style={Styles.button}
         onClick={() => {
                initializePayment(onSuccess, onClose)
            }}>Pay</Button>
      </div>
    );
  }
  
  export default PaystackCart;