import React,{useState,useRef, useEffect} from "react";
import { IoCartSharp, IoLocation } from "react-icons/io5";
import useFetch from "../customHook/useFetch";
import Styles from "../styles/styles";
import url from "./url";
import { useSelector } from "react-redux";
import numberToWords from "../functions/numberToWords";
import PaystackCart from "./PaystackCart";
import ReactGoogleAutocomplete from "react-google-autocomplete";




const CartSection = ({user})=>{
    const trigger = useSelector(state=>state.trigger)
    const [shippingAddress, setShippingAddress]= useState('');
    const [disablePay, setDisablePay] = useState(false);
   
   useEffect(()=>{
    if(shippingAddress===''){
        setDisablePay(true)
    }
    
},[shippingAddress])
   // console.log(disablePay)
    const{data, isPending, error} = useFetch(url(`user/cart/${user._id}`),'GET', "",trigger)
    let i=0
let body={};

    var subtotal = useRef(0);
    

    var vat = useRef(0);
    

    var total = useRef(0);
    
  
data && ( body={
        user:user,
        cart:data,
        total:total.current,
        shippingAddress:shippingAddress,
        
    })
    subtotal.current = 0;
    vat.current = 0;
total.current = 0;
    //console.log(body)
    return(
        <div>
            <p style={Styles.smallBoldText}>Cart <IoCartSharp size={18}/></p>
            {data && data.map((cart,index)=>{
subtotal.current= subtotal.current+(cart.product.value*cart.quantity);
vat.current = vat.current+(subtotal.current*0.075);
total.current = total.current+(subtotal.current*0.075)+subtotal.current
return(
     <div style={{display:'flex', }}>
<p style={Styles.smallText}>{cart.product.item} x{cart.quantity}</p>  
<p style={Styles.smallBlackBoldText}>{numberToWords(cart.product.value*cart.quantity)}</p>
            </div>
)
            })}
           

    
        <div style={Styles.ashWrapperColumn}>
        <p style={Styles.smallBlackBoldText}>7.5% VAT: {numberToWords(vat.current)}</p>
<p style={Styles.smallBlackBoldText}>Total: {numberToWords(total.current)}</p>
        </div>

        <div style={{marginTop:20}}>
            <p style={Styles.smallBlackBoldText}>Shipping address</p>
        <div style={Styles.ashWrapper}>
           <IoLocation size={20} color='#D52600'/>
           <ReactGoogleAutocomplete  style={Styles.input}
            apiKey={"AIzaSyAtwtf-mfEyTeEjAq2FxH3ByADohA-azvg"}
            placeholder="Shipping address"
            onPlaceSelected={(place)=>{
             //   console.log(place)
             setShippingAddress(place)
             setDisablePay(false)
           //  console.log(disablePay)
            }}
            options={{
              types: [( "address")],
              componentRestrictions: { country: "ng" },
            }}
            />
        </div>
        <p style={Styles.smallBlackBoldText}>Arrives in 3 - 30 days</p>
        </div>

        {data && <PaystackCart body={body} disabled={disablePay}/>}

        </div>
    )
}

export default CartSection;