import logo from './logo.svg';
import './App.css';
import'../node_modules/bootstrap/dist/css/bootstrap.min.css';
//import {Button, Modal} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Header from './components/Header';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Products from './pages/Products';
import Home from './pages/Home';
import NoPage from './pages/NoPage';
import AmbulanceServices from './pages/AmbulanceServices';
import CourseDate from './pages/CourseDate';
import Paystack from './components/Paystack';
import SuccessPage from './pages/SuccessPage';
import AppLogin from './pages/AppLogin';
import AppMenu from './components/AppMenu';
import Candidates from './pages/Candidates';
import AdminDates from './pages/AdminDates';
import AddDate from './pages/AddDate';
import cookie from 'react-cookies';
import fetchFunc from './customHook/fetchFunc';
import url from './components/url';
import useFetch from './customHook/useFetch';
import Faq from './pages/Faq';
import { ProSidebarProvider } from 'react-pro-sidebar';
import Dashboard from './components/Dashboard';
import Courses from './pages/Courses';
import ForgotPassword from './components/ForgotPassword';
import ForgotPasswordLink from './components/ForgotPasswordLink';
import VerificationPage from './components/VerificationPage';
import UserCourses from './components/UserCourses';
import ManualRegister from './pages/ManualRegister';
import SiteEquip from './pages/SitesEquip';
import TrainingEquip from './components/TrainingEquip';
import AddProduct from './pages/AddProduct';
import Checkout from './pages/Checkout';
import SuccessfulPurchase from './pages/SuccessfulPurchase';
import CoursDetails from './pages/CourseDetails';
import CoursDetailsObj from './components/CourseDetailsObj';
import InstructorForm from './pages/InstructorForm';
import AppContext from './context/AppContext';
import { useCookies } from 'react-cookie';

 function App() {
  

//const token = cookie.load('id');
const [trigger, setTrigger] = useState(false);
const [token, setToken] = useState("none")
const [cookies, setCookie] = useCookies(['id, account']);
const [userData, setUserData] = useState({})

const{data, isPending, error} = useFetch(url("user/"+cookies.id),"get",null,trigger);
const access = data;

const [user, setUser] = useState("Guest");
const [status, setStatus] = useState("not checked");
const [account,setAccount] = useState("none")

const getData = async()=>{
  return await data
}
useEffect(()=>{
  console.log('cookie> '+cookies.id)
  console.log(getData())
    if (data && data.status==="loggedIn"){
 setAccount(data.account)
setStatus( data.status)
setUser(data.user)
setUserData({})
 }
},[data])





//Start of Tawk.to Script-->

var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/5e1a66bd27773e0d832d14a0/default';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();

//End of Tawk.to Script-->


 return(
  <AppContext.Provider value={{token, setToken, user, setUser, account, setAccount, status, setStatus, cookies, setCookie, getData}}>
   <ProSidebarProvider>
<BrowserRouter 
//basename={
 // '/devserver'
 // '/clientarea'
 // }
 >
<Routes>
  <Route path="/" element={<Header user={user} status={status} account={account} setStatus={setStatus} setUser={setUser} setAccount={setAccount}/>}>
    <Route index element={<Home/>} />
    <Route path="products" element={<Products status={status}/>} />
    <Route path="ambulance" element={<AmbulanceServices/>} />
    <Route path="bls" element={<CoursDetails course={CoursDetailsObj.bls} />} />
    <Route path="course_date" element={<CourseDate user={user} status={status} account={account} userId={token}
   setStatus={setStatus} setUser={setUser} setAccount={setAccount} />} />
    <Route path="paystack" element={<Paystack/>} />
    <Route path="success" element={<SuccessPage/>}/>
    <Route path="successfulpurchase" element={<SuccessfulPurchase/>}/>
    <Route path="login"  element={<AppLogin status={status} setStatus={setStatus} setUser={setUser} setAccount={setAccount} />}/>
    <Route path="forgotpassword"  element={<ForgotPassword status={status} />}/>
    <Route path="forgotpasswordlink/:userId/:token"  element={<ForgotPasswordLink status={status} />}/>
    <Route path="verification/:userId/:token"  element={<VerificationPage status={status} />}/>
    <Route path="instructorcandidateform" element={<InstructorForm/>}/>
   {// <Route path="faq"  element={<Faq/>}/>
   }
    <Route path="user/" element={<AppMenu user={user} status={status} account={account}/>}>

     <Route index path="dashboard" element={<Dashboard/>}/> 

      <Route  path="candidates" element={<Candidates/>}/>
      <Route  path="courses" element={<UserCourses/>}/>
      <Route  path="dates" element={<AdminDates account={account}/>}/>
      <Route  path="site_equip" element={<SiteEquip account={account}/>}/>
      <Route  path="training_equip" element={<TrainingEquip account={account}/>}/>
      <Route  path="manual_register" element={<ManualRegister/>}/>
      <Route path="add_date" element={<AddDate userId={token}/>}/>
      <Route  path="addproduct" element={<AddProduct account={account} user={user}/>}/>
      <Route  path="checkout" element={<Checkout account={account} user={user}/>}/> 
 
      
      </Route>
     
    <Route path="*" element={<NoPage />} />
  </Route>
</Routes>
</BrowserRouter></ProSidebarProvider>
</AppContext.Provider>
 );

}

export default App;
