import React from "react";
import { Container } from "react-bootstrap";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import numberToWords from "../functions/numberToWords";
import Styles from "../styles/styles";


const SuccessfulPurchase = ({})=>{
//
const convertDate =(rawDate)=> new Date(rawDate).toDateString();
const {state} = useLocation();
console.log(state);

    return(
        <Container>
            <div style={Styles.centerWrapper} className='d-flex justify-content-center align-items-center flex-column'>
            {state.data.error && <p style={Styles.redText}>There was an error!</p>}
{!state.data.error && <div className='d-flex justify-content-center align-items-center flex-column' ><h1>
    Your order has been placed
</h1>

<p><BsFillCheckCircleFill size={90} color="green"/></p>

{state.data.payment.cart.map((content)=>{

    return(<div>
        <b>{content.product.item}: X{content.quantity}</b>
        </div>)
})}
<b>Total:</b><h5>{numberToWords(state.data.payment.total)}</h5>
<p style={Styles.smallBoldText}>Shipping Address</p>
<p style={Styles.smallBlackBoldText}>{state.data.payment.shippingAddress.formatted_address||state.data.payment.shippingAddress.name}</p>
<p>A payment confirmation has been sent to your email address</p></div>}
<p>For more information call us on: <a href="tel:08146821431">08146821431</a></p>

</div>
</Container>

    )


}

export default SuccessfulPurchase;