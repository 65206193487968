import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal} from 'react-bootstrap';
import {useState} from 'react';
import Header from '../components/Header';
import ProductCard from '../components/ProductCard';
import CustomInput from '../components/CustomInput';
import Styles from '../styles/styles';
import fetchFunc from '../customHook/fetchFunc';
import url from './url';
import Course from './Course';
import User from './User';
import Infobox from './Infobox';
import UserCourses from './UserCourses';
import cookie from 'react-cookies';
import useFetch from '../customHook/useFetch';
import { useCookies } from 'react-cookie';





const Dashboard=({})=>{

const [cookies, setCookie] = useCookies(['id']);
 
    //console.log(access);
    const [trigger, setTrigger] = useState(false);
    
    const{data, isPending, error} = useFetch(url(`user/usercourses/${cookies.id}`),"GET",null,trigger);
//data && console.log(data)

    
function infoArray (title, value, fontColor,){
    this.title = title;
    this.value = value
    this.fontColor = fontColor
}

    const courseHistory= [
        
        new infoArray("Course Registered",data && data.length,"#0F124E"),
        new infoArray("Completed",data && data.filter((userCourseList)=>{
       return     userCourseList.training_date && 
        userCourseList.training_date.status==="completed"
    
    }).length,"#707070"),
        new infoArray("Course Expired",0,"#707070"),

    ]

    const courseLink={title:"see course...", value:"/user/courses"}


    return(
        <div style={Styles.wrapper}>

<User/>
<Infobox title="" infoArray={courseHistory} link={courseLink} backgroundColor="#AF0000" />
     
    
           
        </div>
    );

}

export default Dashboard;