import React from "react";
import fetchFunc from "../customHook/fetchFunc";
import url from "./url";
import { Link, useNavigate } from 'react-router-dom';

const LogOut = () =>{

    const navigate = useNavigate();
    const logOut =async()=>{
       if( await fetchFunc(url("auth/logout"),"GET",null,null)){
        //navigate("/clientarea/");
        window.location.replace("/clientarea/"); // does not appear on history
       };
    }
return(
    <h5 onClick={()=>logOut()}>Log Out</h5>
)

}

export default LogOut;