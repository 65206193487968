import React from "react";
import { useState } from "react";
import useFetch from "../customHook/useFetch";
import Styles from "../styles/styles";
import DatePicker from 'react-date-picker';
import fetchFunc from "../customHook/fetchFunc";
import url from "../components/url";
import { Calendar } from "react-bootstrap-icons";
import { Link, useNavigate } from 'react-router-dom';
const AddDate=({userId})=>{
 
 const navigate = useNavigate()
  const [filter, setfilter] = useState({courses:[],
    venue:"", start:"", stop:""

});
const [trigger, setTrigger] = useState("");
    const {data, isPending, error} = useFetch(
      url(`course_dates?codes=${filter.courses}&venue=${filter.venue}&start=${filter.start}&stop=${filter.stop}`),
      "GET", null, trigger);

data && console.log(data);
    const [date, setDate] = useState(null);
    const [course, setCourse] = useState(null);
    const [venue, setVenue] = useState(null);
    const [result, setResult] = useState("");
    const [mode, setMode] = useState("not specified");
    const [code, setCode] = useState(null);
    const [visible, setVisible] = useState('visible')
    const [errmsg, setErrmsg] = useState('')


    const body = {date,course,mode,venue,code,userId};


    //fix dates func
const  fixDate=(e)=>{


switch (e.target.id) {

    case "course": {
      setCourse(data.products[e.target.value]._id);
      setCode(data.products[e.target.value].code)
     
  
    } 
      break;

      case "mode": {
        setMode(e.target.value);
      
      } 
        break;

      case "venue": {
        setVenue(e.target.value);
      
      } 
        break;


  default:
    break;
}
console.log(body)
}

const sendDate=async (e)=>{
  e.preventDefault();
 // console.log(body); 
setVisible('hidden')
await fetchFunc(url('user/addcoursedate/'),"POST",body).then((result)=>{
  setResult(result)
 result.date && window.alert("New course created,/n code:"+ result.code+",/n date:"+  result.date);
 result.msg && window.alert(result.msg)
}).then(()=>{navigate("/user/dates")
}).catch(err=>{
  setVisible('visible')
});
console.log(result)
 
  
  
}


    return(
      <div style={{visibility: visible}}>
            
      <div style={Styles.centerWrapper}>
      <h5>CREATE COURSE</h5>
      <p>{errmsg.toString()}</p>
      {result && result.status===201 && <p>{result.response}. Course: {result.res.code}, Date: {result.res.date}</p>}
      <form onSubmit={(e)=>sendDate(e)}>

<div>
  <div>
  <label style={Styles.smallBoldText}>Course</label>
  <select style={ Styles.input2} onChange={(e)=>{fixDate(e)}} id="course" aria-label="Default select example">
  <option value="">Choose course</option>
{data && data.products.map((course, i)=>{
 return(
  <option key={i} value={i} >{course.item}</option>
 )
})} 

</select>    
  <p>
    Please choose a course.
  </p>
</div>

<div>
  <label style={Styles.smallBoldText}>Date  </label>
<DatePicker  calendarIcon={<Calendar/>} calendarClassName ="date" onChange={(Date)=>{setDate(Date)}} value={date} />

<p>
    Please pick a date.
  </p>
</div></div>

<div>

<div>
  <label style={Styles.smallBoldText}>Mode</label>
  <select style={Styles.input2} onChange={(e)=>{fixDate(e)}} id="mode" aria-label="Default select example">
 
  <option value="not specified">Not specified</option>
<option value="in-person">In-person</option>
<option value="online">Online</option>

</select>    
  <p>
    Please choose class mode.
  </p>
</div>
<div>
  <label style={Styles.smallBoldText}>Venue</label>
  <select style={Styles.input2} id="venue" onChange={(e)=>{fixDate(e)}} aria-label="Default select example">
  <option  value=''>Pick a venue</option>
  {data && data.venues.map((venue, i)=>{

return(
<option id={i} value={venue._id}>{venue.title+": "+venue.location}</option>
)
})
} 


</select>
  <p>
    Please Pick a venue.
  </p>
</div>
</div>

<div>
  <input type="checkbox" label="Check me out" />
</div>
<button style={Styles.classButton} variant="primary" type="submit">
  Submit
</button>
</form>
      </div>
  </div>
    )
}

export default AddDate;