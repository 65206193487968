import React, { useEffect, useState } from "react";
import Styles from "../styles/styles";
import { Outlet, Link, useNavigate } from "react-router-dom";
import LogOut from "./LogOut";
import useFetch from "../customHook/useFetch";
import url from "./url";
import { Sidebar,Menu,MenuItem,useProSidebar,SubMenu } from "react-pro-sidebar";
//import { ProSidebar, Menu, MenuItem, SubMenu } from '@randumbwilliam/react-pro-sidebar';
//import '@randumbwilliam/react-pro-sidebar/dist/css/styles.css';
import {  BarChartSteps, Briefcase, Cart, Dash, DoorClosed, HeartPulse } from "react-bootstrap-icons";
import { IoCalendarClear, IoChevronBack, IoChevronForward, IoPerson, IoSchool, IoBook, IoBusiness } from "react-icons/io5";
import { IoIosCart, IoMdConstruct } from "react-icons/io";


const AppMenu =({user, status,account})=>{
  
  const [collapse,setCollapse] = useState(true)
const color = "#950606"
//const [trigger, setTrigger] = useState("");
//const{data, isPending, error} = useFetch(url("user/631342cb9ab82561f42ef30b"),"get",null,trigger);
const { collapseSidebar } = useProSidebar();
let navigate = useNavigate();


useEffect(()=>{
   if (status !== "loggedIn"){
navigate("/login/"); // does not appear on history
}

},[])
 //status!=="logged"
const toggleCollapse=()=>{
  collapse === true? setCollapse(false):setCollapse(true)
}

return(
  <>

  <div style={{display:"flex", position:"relative", color: "#383b40"  }}>

    {status ==="loggedIn" && 
  <div style={{height:"auto", position:"relative"}}>   
<Sidebar  defaultCollapsed={collapse}  toggled={true} style={{height:"100%", }}
title="Dashboard" collapsedWidth="60px" width="200px"
 //</div>onToggle={()=>collapse === false? setCollapse(true):setCollapse(false)}breakPoint="xl"
 >
  <Menu iconShape="square" style={{height:"100%", margin:0, paddingLeft:0,}}>
    {collapse === false? <MenuItem icon={<IoChevronBack/>} onClick={()=>{toggleCollapse()}}></MenuItem>:
    <MenuItem icon={<IoChevronForward/>} onClick={()=>{toggleCollapse()}}></MenuItem>}

    <Link  to="/user/dashboard"><MenuItem  icon={<Briefcase/>}>Dashboard</MenuItem></Link>
    {account ==="instructor" &&
       <SubMenu icon={<IoPerson/>}color="red" label="Instructor"> 
       <Link   to="/user/dates"><MenuItem icon={<IoCalendarClear/>}>Courses
     </MenuItem></Link>
     <Link to="site_equip"  disabled><MenuItem icon={<IoMdConstruct/>}>Sites and Equipments</MenuItem></Link>
     </SubMenu> }
     {account ==="instructor" &&
       <SubMenu icon={<IoBusiness/>}color="red" label="Admin"> 
       <Link   to="/user/addproduct"><MenuItem icon={<IoIosCart/>}>Add Products
     </MenuItem></Link>
     <Link to="site_equip"  disabled><MenuItem icon={<IoMdConstruct/>}>Sites and Equipments</MenuItem></Link>
     </SubMenu> }
    <SubMenu label="Certification" icon={<IoSchool/>}>
       
      <Link to="/user/Courses" disabled><MenuItem icon={<IoBook/>}> My Courses</MenuItem></Link>
      <Link to=""  disabled><MenuItem icon={<IoBook/>}>New Courses</MenuItem></Link>
    </SubMenu>
    <SubMenu label="Services" icon={<HeartPulse/>}>
       <Link to="" disabled><MenuItem  icon={<IoCalendarClear/>}>Ambulance Services</MenuItem></Link>
     </SubMenu>
    <MenuItem icon={<DoorClosed/>}>
        <LogOut/></MenuItem>
  </Menu>
  
</Sidebar>

</div> }
   <div style={{display:'flex', justifyContent:'center', width:'100%'}}> 
  <div style={{ display: "flex", flexDirection:"column",
    justifyContent: "start", position:"relative",
    //alignItems: "center",
    overflowX:"visibile",
   
    padding:0,
    width:"auto",}}> <Outlet/></div> </div>
    </div>
    </>
)
}

export default AppMenu;