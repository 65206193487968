import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal, Form} from 'react-bootstrap';
import {useState} from 'react';
import Header from './Header';
import CustomInput from './CustomInput';
import Styles from '../styles/styles';

import Paystack from './Paystack';
import useFetch from '../customHook/useFetch';
import url from './url';
import DatePicker from 'react-date-picker';
import { IoIosMail, IoIosPhonePortrait } from 'react-icons/io';
import numberToWords from '../functions/numberToWords';

const RegisterAsGuest=({date, color, userId, dateData})=>{
    //data removed
   
    const person = new Object();

const [firstname, setFirstname] = useState("First Name")
const [lastname, setLastname] = useState("Last Name")
const [email, setEmail] = useState("email")
const [phone, setPhone] = useState("Phone number")

const [form, setForm] = useState({
    _id:'none',
    firstname:'Firstname',
    lastname:'Lastname',
    email:'Email',
    phone:'Phone number'
})

     //calculate total cost

     //ebookcost initially set at null
    const [ebookCost, setEbookCost] = useState(0);
    const [purchase, setPurchase]= useState([])

    //total cost calculates cost of both the book & the course
    const totalCost = (ebookCost, courseCost) =>{
        var costInKobo = courseCost + ebookCost
return costInKobo
    }
  //total cost ends here  


  
  //retreve user info
  const trigger ="";
//const{data, isPending, error} = useFetch(url(`user/userdetails/${userId}`),"get",null,trigger);
//data && console.log(data)
  const test =false;
    //body sent to server
    
    const body = {
     
        form,
        
        trainingDateData:dateData,
        amount:totalCost(ebookCost,dateData.course.value),
        cart:[dateData.course.item],
        ebookAdded:false,
        purchaseRecord:[form.firstname+ " "+form.lastname+ " paid ₦"
        +(dateData.course.value/100).toLocaleString()+" for "+dateData.course.item
        +" scheduled on "+ new Date(dateData.date),...purchase]
        };

        console.log(body.purchaseRecord)
//error might arise from item not being specific
        const addItem = (item)=>{
            setEbookCost(item.value);
            setPurchase([item.item+" was added at ₦"+(item.value/100).toLocaleString(),...purchase])
            body.ebookAdded=true
         //   body.items.push(item.item)
      
        }

        const removeItem = (item) =>{
            setEbookCost(0)
          setPurchase(purchase.filter((record)=>{
                return record != item.item+" was added at ₦"+(item.value/100).toLocaleString()
            }))  
            body.ebookAdded=false
      //   body.items= body.items.filter((item)=>{
//return body.items !== item.item
     //    })


        
        }
        var courseFeeToNaira = dateData.course.value/100
var ebookFeeToNaira = ebookCost/100

        

    return(
        <div style={Styles.background} >
            
       <h3 style={Styles.textHeader}>ENROLL AS A GUEST</h3>
        <div style={Styles.centerWrapper}> 
        <div style={{backgroundColor:color, height:"100%", width:"100%",padding:10, color:"#363636"}}>  
        <h4>{dateData.course.item.toUpperCase()}</h4>
        <h5>{date}</h5>
        <h6>{dateData.venue.location}</h6>
        </div>
        </div> 
        {//<p>Please fill the form below</p>
}
      
        <div style={Styles.centerWrapper}> 
        <div>
        
        <CustomInput  placeholder="Firstname"  setValue ={setForm} value={form} name={'firstname'}/>
        <CustomInput placeholder="Lastname" setValue ={setForm} value={form} name={'lastname'}/>
        <IoIosMail size={30}/> 
        <CustomInput placeholder="email" setValue ={setForm} value={form} type='email' name={'email'}/>
       <IoIosPhonePortrait size={30}/>
        <CustomInput placeholder="Phone number" setValue ={setForm} value={form} name={'phone'}/>
        </div></div>
        <div style={Styles.centerWrapper}> 
        
            <input onChange={()=>{(ebookCost===0)?addItem(dateData.ebook):removeItem(dateData.ebook)}} style={{borderColor:"red", color:"maroon"}} type="checkbox" />
        <label style={Styles.smallBoldText}> Add Course Manual Ebook</label>
        <p style={Styles.smallBoldText}> Please deselect this option if you already have a provider manual</p>
        
        
      <p style={Styles.smallBoldText}>Course fee: ₦{courseFeeToNaira.toLocaleString()}</p>
      {ebookCost !== 0 && <p>E-book fee: {ebookFeeToNaira.toLocaleString()}</p>}
      <h4>Total: {(totalCost(ebookCost,dateData.course.value)/100).toLocaleString()}</h4>
</div>
      
{body && <Paystack body={body}/>}
        
        </div>
    );

}

export default RegisterAsGuest;