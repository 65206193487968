import React,{useState} from "react";
import Styles from "../styles/styles";
import StreamedImage from "./StreamedImage";

const PictureBank =({images})=>{

let imagess = [
    {id:1, name:'1st image', url:'1678055599872.png'},
    {id:2, name:'2nd image', url:'1678055599872.png'},
    {id:3, name:'3rd image', url:'1678055599872.png'},
    {id:4, name:'5th image', url:'1678055599872.png'},
]

    return(
        <div style={{display:'flex', flexWrap:'wrap'}}>
{images.map((image)=>{


return(
    <StreamedImage name={image.filename}/>
)
})}
        </div>
    )
}

export default PictureBank;