import React from "react";
import Styles from "../styles/styles";
import SimpleImageSlider from "react-simple-image-slider";
import numberToWords from "../functions/numberToWords";
import SliderImages from "./SliderImages";
import { useDispatch } from "react-redux";
import url from "./url";
import fetchFunc from "../customHook/fetchFunc";
import cookie from "react-cookies";
import AddToCart from "./AddToCart";





const ProductDetails =({id,item,images,price,location,transact,description,user})=>{

    

    return(


        <div style={{
            minWidth:50,
            maxWidth:500,
            width:'100%', 
            }}>
         <div style={{display:'flex', justifyContent:'center'}}> <SliderImages images={images}/></div>
     
               
                <p style={Styles.boldText}>{item}</p>
                <p>{description}</p>
        <p style={Styles.smallBoldText}>{numberToWords(price)}</p>
        <p style={Styles.label}>{location}</p>
        <div style={{position:'absolute', right:20, bottom:30}}>
        <AddToCart user ={user} id= {id} quantity={1}/>
    
    </div>
    </div>
    )
}

export default ProductDetails;