import React, {useState} from "react";
import useFetch from '../customHook/useFetch';
import url from "../components/url";
import { Dropdown } from "react-bootstrap";
import Styles from "../styles/styles";
import { ArrowRight, Search, ThreeDots } from 'react-bootstrap-icons';
import CustomInput from "../components/CustomInput";
import { useNavigate } from "react-router-dom";
import CustomModal from "../components/CustomModal";
import '../Custom.css';
import CandidateNumber from "../components/CandidateNumber";


const AdminDates=({account})=>{
  const [show, setShow] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [trigger, setTrigger]= useState(true);
    const [filter, setfilter] = useState({courses:[],
      venue:"", start:"", stop:""
  
  });
    const {data, isPending, error} = useFetch(url(`course_dates?codes=${filter.courses}&venue=${filter.venue}&start=${filter.start}&stop=${filter.stop}`),"GET", null, trigger);
    const dates = data;
const Navigate = useNavigate();

const activateTrigger = () =>{
  trigger ===false?setTrigger(true):setTrigger(false)
}

if(account ==='instructor'){


    return(
        <div style={Styles.wrapper} className="container-fluid">
            <h4>DATES</h4>
            <div>
           
  
  <CustomModal show={showFilter} handleClose={()=>setShowFilter(false)}>
  <div>
      {data && data.products.map((product,i)=>{


return(
    <div class="form-check">
  <input class="form-check-input" type="checkbox" value={product.code} onClick={(e)=>{
      //checked===true ? setChecked(false): setChecked(true);
      if(e.target.checked && !filter.courses.includes(e.target.value)){ filter.courses.push(e.target.value)
        activateTrigger()
    } else{ 
        let index = filter.courses.indexOf(e.target.value)
       
            filter.courses.splice(index, 1);
          activateTrigger()
        }
        console.log(filter)
  }} id="" />
  <label class="form-check-label" for="flexCheckDefault">
   {product.code.toUpperCase()}
  </label>
  </div>
)
      })}

          <h4>Venue</h4>

          <select class="form-select" aria-label="Default select example" onClick={(e)=>{
              filter.venue = e.target.options[e.target.selectedIndex].text
              console.log(filter)
              activateTrigger()
          }}>
          <option value="*" selected>All</option>
              {data && data.venues.map((venue, i)=>{
                
                  return(
<option value={venue.title}>{venue.title}</option>
                  )
              })}
  

</select>
          
          <h4>Date</h4>
          <p>Start</p>
          <input type="date" class="form-control" onChange={(e)=>{filter.start = e.target.value; activateTrigger()}} id="exampleInputPassword1"/>
          <p></p>
          <p>End</p>
          <input type="date"  class="form-control" onChange={(e)=>{filter.stop = e.target.value; activateTrigger()}} id="exampleInputPassword1"/>
</div>
      </CustomModal>
            </div>
           
           
           
           
               <div className="buttonspread">
              <button style={Styles.button} variant="custom" onClick={()=>setShowFilter(true)}>Filter</button>
             
                   <button onClick={()=>Navigate('/user/add_date')} style={Styles.button}>Add Date</button>

                   </div>
            
            <div class="" style={{width:"", overflowX:"scroll"}} >
            
    
      { dates && dates.dates.map((date, i)=>{

return(
        <div style={{width:"auto", display:"flex", flexWrap:"wrap", background:"white", borderRadius:10,
        margin:10, borderColor: "gray", borderStyle:"outset"}}  key={i}>
          <div style={{border: 'none',  borderWidth:0, padding:10, maxWidth:150}}>
            <h5>ID</h5>
            <p style={{border: 'none',  borderWidth:0}}>NIL</p>
          </div>
          
          <div style={{border: 'none',  borderWidth:0, padding:10, maxWidth:150}}>
            <h5>Date</h5>
            <p style={{border: 'none',  borderWidth:0}}>{new Date(date.date).toDateString()}</p>
          </div>

          <div style={{border: 'none',  borderWidth:0, padding:10, maxWidth:150}}>
            <h5>Course</h5>
            <p style={{border: 'none',  borderWidth:0}}>{date.course.item.toUpperCase()}</p>
          </div>

          <div style={{border: 'none',  borderWidth:0, padding:10,maxWidth:150}}>
            <h5>Venue</h5>
            <p style={{border: 'none',  borderWidth:0}}>{date.venue.location}</p>
          </div>

          <div style={{border: 'none',  borderWidth:0, padding:10,maxWidth:150}}>
            <h5>Participants</h5>
            <CandidateNumber dateId={date._id}/>
          </div>

          <div style={{border: 'none',  borderWidth:0, padding:10,maxWidth:150}}>
            <h5>Status</h5>
            <p style={{border: 'none',  borderWidth:0}}>in progress</p>
          </div>

          <div>
            <h5></h5>
            <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-basic"
      style={{border:"none", maxHeight:30,boxShadow:"none"}} 
      className="btn btn-xs">
      <ThreeDots color="royalblue" size={20}/>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={()=>{Navigate('/user/candidates',{
          state:{
            course:date.course.item.toUpperCase(),
          date: new Date(date.date).toDateString(),
          venue: date.venue.location,
          id: date._id,
          }
            })
            }}>Registered Candidates</Dropdown.Item>
            <Dropdown.Item onClick={()=>{Navigate('/user/manual_register',{
          state:{
            course:date.course.item.toUpperCase(),
          date: new Date(date.date).toDateString(),
          venue: date.venue.location,
          id: date._id,
          }
            })
            }}>Add Candidate</Dropdown.Item>
        
        <Dropdown.Item href="#/action-3">Suspend</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </div>
          
         </div>

        
)
      })
    }
        
     
    </div>
        </div>
    )
  }
  else{
    Navigate('/');
    return(
      <div style={Styles.container}>
        <p style={Styles.boldText}>Not found</p>
      </div>
    )
  }
}

export default AdminDates;