import React,{useState} from 'react';
import Styles from '../styles/styles';
import CartSection from '../components/CartSection';
import url from '../components/url';
import cookie from 'react-cookies';
import useFetch from '../customHook/useFetch';





const Checkout =({status})=>{

    const userId = cookie.load('id');
//retreve user info
const trigger ="";
const{data, isPending, error} = useFetch(url(`user/userdetails/${userId}`),"get",null,trigger);

    return(
    <div style={Styles.whiteBackgroundWrapper}>
        {data && <CartSection user={data} />}
       </div> 
    )
}

export default Checkout;