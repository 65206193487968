import '../App.css';
//import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal} from 'react-bootstrap';
import {useState} from 'react';
import Header from '../components/Header';
import CustomModal from '../components/CustomModal';
import FilterCourse from '../components/FilterCourse';
import Dates from '../components/Dates';
import Registration from '../components/Registration';
import useFetch from '../customHook/useFetch';
import { useParams } from 'react-router';
import Styles from '../styles/styles';
import { useSelector, useDispatch } from 'react-redux';
import url from '../components/url';
import ScaleLoader from "react-spinners/ScaleLoader";
import { Container } from 'react-bootstrap';
import { IoFilterCircle } from 'react-icons/io5';


const CourseDate=({user, status, userId,setStatus, setAccount, setUser})=>{
    const [show, setShow] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [registration, setRegistration] = useState(false);
    const [filter, setfilter] = useState({courses:[],
        venue:"", start:"", stop:""
    
    });

    const [checked, setChecked] = useState(false);
    const courseRegistration = useSelector(state=>state.courseRegistration)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [trigger, setTrigger] = useState(false)
    const {data, isPending, error} = useFetch(url(`course_dates?codes=${filter.courses}&venue=${filter.venue}&start=${filter.start}&stop=${filter.stop}`),"GET", null, trigger);
    data && console.log(data,isPending,error);
  //var date={};


const activateTrigger = () =>{
    trigger ===false?setTrigger(true):setTrigger(false)
}



    
    return(
        <div>
           <div style={{padding:10}}><IoFilterCircle color='#581845' onClick={()=>setShowFilter(true)} size={40}/></div>
  
  <CustomModal show={showFilter} handleClose={()=>setShowFilter(false)}>
  <div>
      {data && data.products.map((product,i)=>{


return(
    <div key={i} class="form-check">
  <input class="form-check-input" type="checkbox" value={product.code} onClick={(e)=>{
      //checked===true ? setChecked(false): setChecked(true);
      if(e.target.checked && !filter.courses.includes(e.target.value)){ filter.courses.push(e.target.value)
        activateTrigger()
    } else{ 
        let index = filter.courses.indexOf(e.target.value)
       
            filter.courses.splice(index, 1);
          activateTrigger()
        }
        console.log(filter)
  }} id="" />
  <label class="form-check-label" for="flexCheckDefault">
   {product.code.toUpperCase()}
  </label>
  </div>
)
      })}

          <h4>Venue</h4>

          <select class="form-select" aria-label="Default select example" onClick={(e)=>{
              filter.venue = e.target.options[e.target.selectedIndex].text
              console.log(filter)
              activateTrigger()
          }}>
          <option value="*" selected>All</option>
              {data && data.venues.map((venue, i)=>{
                
                  return(
<option key={i} value={venue.title}>{venue.title}</option>
                  )
              })}
  

</select>
          
          <h4>Date</h4>
          <p>Start</p>
          <input type="date" class="form-control" onChange={(e)=>{filter.start = e.target.value; activateTrigger()}} id="exampleInputPassword1"/>
          <p></p>
          <p>End</p>
          <input type="date"  class="form-control" onChange={(e)=>{filter.stop = e.target.value; activateTrigger()}} id="exampleInputPassword1"/>
</div>
      </CustomModal>

     
      <div className="row">
          { //make this a loader component
          isPending && <div style={{margin:20, minHeight:200}}>
             
          <Container style={{height:"100%"}} className='d-flex justify-content-center align-items-center flex-column'>
         
          <ScaleLoader color={"#E92C2C"} loading={true} cssOverride={Styles.override} size={150} />
         
          
          </Container> 
              
              </div>}
          {error && <div><p>{error}</p></div>}

      { data && data.dates.map((dateData,i)=>{

/*let ebookCost = data.costs.filter((ebookCost)=>{
    console.log(ebookCost.title === date.course);
    return ebookCost.title === date.course;
})*/
return (

<div key={i} className="col-sm-4">

<Dates key={i} 
 dateData ={dateData}
/*course={date.course.item} date={date.date} address={date.venue}
 product={date.product||{text:"0.00", value:0}} ebook={date.ebook} 
 id={date._id} code={date.course.code} */
 user={user} status={status} userId={userId} setStatus={setStatus} setAccount={setAccount} setUser={setUser}
 />
 {// goes to date component
 }
</div>
)
      })}
      
      </div>
      

      <div>
          <h2 style={Styles.textHeader}>Discount packages</h2>
      </div>
      
        </div>
    );

}

export default CourseDate;