import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Col, Container, Form, Modal, Row} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Header from '../components/Header';
import ProductCard from '../components/ProductCard';
import CustomInput from '../components/CustomInput';
import Styles from '../styles/styles';
import useFetch from '../customHook/useFetch';
import fetchFunc from '../customHook/fetchFunc';
import axiosFetch from '../customHook/axiosFetch';
import url from './url';
import { useSelector , useDispatch} from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import useLoginStatus from '../customHook/useLoginStatus';
import cookie from 'react-cookies';
import ScaleLoader from "react-spinners/ScaleLoader";


const VerificationPage= ({lurl,redirect, status})=>{


//navigation
const navigate = useNavigate();

  //fetch info]from url
  let { userId, token } = useParams();
  //body to be sent
const body = { userId, token, request:"login"}

     const [loginError, setLoginError] = useState(null);
   
     const [messageSent, setMessageSent] = useState(null);
     const [reply, setReply] = useState(null);

     //if logged in redirect 
      if (status==="loggedIn"){
     
    navigate(redirect);
     }


    //set validation for password
    const send = async()=>{
      
        setLoginError(null)
    // setUser( axiosFetch("https://39f5-41-217-100-12.ngrok.io/admin/login/",email,password)) ;

 
      let  verification = await fetchFunc(url(`auth/verificationlink/${userId}/${token}`),"POST",body,true)
    
      //if successful
     if (verification && verification.msg === "password reset sucessfully."){
//console.log(email.msg)

        setMessageSent(true);
        setReply(verification.msg)
     }

     //if unsuccessfull
if(!verification.msg !== "password reset sucessfully."){


   setLoginError(JSON.parse(verification).msg)
    //setLoginError(email.msg)
  }
    
    }
   useEffect(()=>{
       send()
   },[])

    return(
        <Container className='d-flex justify-content-center'>


   <div>
  <div style={Styles.centerWrapper}>


     
           <h3 style={Styles.textHeader}>Verification</h3>
           <p>{loginError}</p>
     
             

             {messageSent && <h5>{reply}</h5>}
             <Link to="/login" >Back to Login</Link>
             

</div>
</div>


       </Container>
   );

}

export default VerificationPage;