//import '../App.css';
//import'../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal} from 'react-bootstrap';
import {useState} from 'react';
import Header from '../components/Header';
import DeadEnd from '../images/deadend.png'

const NoPage=()=>{

    return(
        <div className='d-flex justify-content-center'>
            <img height={300} src={DeadEnd}/>
        </div>
    );

}

export default NoPage;