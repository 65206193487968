import Background from '../images/pattern.png';
import homeBackground from "../images/medbg.png";
import patternBackground from "../images/pattern2.png";
import landingImage from '../images/cprtaylor2.jpg';

const card ={
    color:"#646464",
    margin:10,
    //backgroundImage: `url( "${ patternBackground } ")`,
   // backgroundSize:'contain',
    minWidth:300,
   
}

const dateCard ={
    color:"#646464",
    margin:5,
    //backgroundImage: `url( "${ Background } ")`,
    backgroundColor: '#F1F1F1',
    boxShadow:"-0px 5px 7px #9E9E9E",
   opacity:1,
    minHeight:250,
    padding:15,
    display:'block',
    position:'relative',
    borderRadius: 25,
}

const background = {
  //  backgroundImage: `url( "${ patternBackground } ")`,
    backgroundSize:'contain',
}

const actionKey = {
    color:"black",
    border:"none",
    borderColor:"gray"
}

const textHeader ={
    color:"black",
    padding:10,
    fontSize:20,
} 

const successText = {
    color:"green"
}

const centerWrapper ={
   // flexDirection:"row",
   // textAlign: "center",
   // maxWidth:500,
   boxShadow: "-0px 5px 7px #9E9E9E",
padding:10,
margin:0,
//minWidth:200,
//overflowX: "hidden"
}

const articleWrapper ={
    // flexDirection:"row",
    // textAlign: "center",
     maxWidth:800,
    boxShadow: "-0px 5px 7px #9E9E9E",
 //padding:10,
 margin:0,
 //minWidth:200,
 //overflowX: "hidden"
 }

const container ={
    padding: 10,
    width: "100%", 
    display:"block",
    justifyContent: "center",
}

const whiteBackgroundWrapper = {
    minWidth:200,
    padding:5,
    margin:5, 
    backgroundColor:'white',
    boxShadow: "-0px 5px 7px #9E9E9E",
}

const ashWrapper = {
    minWidth:250,
   // height:30,
    display:'flex',
    paddingTop:0,
    paddingHorizontal:5,
    justifyContent:'space-between',
    alignItems:'center',
    margin:5, 
    backgroundColor:'#E7E7E7',
    borderRadius:1,
    lineHeight:1,
    
}

const ashWrapperColumn = {
    
    alignItems:'center',
    maxWidth:200,
   // height:20,
    padding:5,
    paddingHorizontal:10,
    margin:5, 
    backgroundColor:'#E7E7E7',
    borderRadius:1,
    lineHeight:1,
    
}

const pictureInput = {
    position:'absolute',
    zIndex: 1,
    opacity: 0,
   backgroundColor:'red' ,
    cursor: 'pointer',
    right: 0,
    top: 0,
    height: 20,
    fontSize: 10,
    width: '100%',
    borderRadius:25,
    }

const flexContainer ={
    padding: 10,
    width: "100%", 
    display:"flex",
    justifyContent: "center",
    flexWrap:'wrap'
}
const authWrapper ={
    // flexDirection:"row",
    // textAlign: "center",
    // maxWidth:500,
    boxShadow: "-0px 5px 7px #9E9E9E",
 padding:10,
 margin:0,
 minWidth:250,
 maxWidth:500,
 margin:5,
 //overflowX: "hidden"
 }

 const producthWrapper ={
    // flexDirection:"row",
    // textAlign: "center",
    // maxWidth:500,
    boxShadow: "-0px 5px 7px #9E9E9E",
 padding:10,
 margin:0,
 minWidth:250,
 maxWidth:300,
 width:'100%',
 margin:5,
 //overflowX: "hidden"
 }

const button = {
    backgroundColor:"#C80202",
    color:"white",
    borderWidth:0,
    maxWidth:250,
    minWidth:100,
    height:30,
    padding:3,
    borderRadius:10,
    boxShadow:"-0px 5px 7px #9E9E9E",
    margin:10,
}
const disabledButton = {
    backgroundColor:"grey",
    color:"darkgrey",
    borderWidth:0,
    maxWidth:250,
    minWidth:100,
    height:30,
    padding:3,
    borderRadius:10,
    boxShadow:"-0px 5px 7px #9E9E9E",
    margin:10,
}

const wrapper = {
    padding:10,
    width:"auto",
   
  
}

const label = {
    width:"100%",
    fontSize:15,
    fontWeight:"bold",
    color:"black",
}

const labelwhite = {
    width:"100%",
    fontSize:15,
    fontWeight:"bold",
    color:"white",
}

const error = {
    width:"100%",
    fontSize:19,
    fontWeight:30,
    color:"#D71010",
}

const input = {
    backgroundColor: "transparent",
    height:18,
   // width:'90%',
    borderColor:"#F4F4F4",
    borderWidth:0,
    borderRadius:3,
    marginLeft:3,
    color:"black",
    //fontWeight:'bold',
}

const input2 = {
    backgroundColor: "white",
    height:38,
    width:"100%",
    borderColor:"#343434",
    borderWidth:0,
    borderRadius:3,
    marginLeft:0,
    color:"#343434",
    boxShadow: "-0px 5px 7px #9E9E9E",
    paddingLeft:10,
}

const inputContainer = {
    backgroundColor: "transparent",
   // height:68,
    width:"100%",
    borderColor:"#F4F4F4",
    borderWidth:1,
    borderRadius:10,
    marginTop:10,
    padding:5,
    color:"black",
    display:'inline-block'
    
}

const inline = {
    display:"inline"
}

const framelessButton = {
    borderWidth:0,
    backgroundColor:"transparent",
    fontSize:15,
    fontWeight:"bold",
}

const inputFocus ={
    borderColor:"red"
}

const trigger={
    fontWeight:"bold",
}

const submenu ={
    color:"#696969",
    backgroundColor:"#E1E1E1"
}

const searchInput = {
    boxShadow:"none",
    
}

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  }

  const home = {
     // backgroundImage:`url( "${ landingImage } ")`,
      //backgroundSize:'cover',
      background:`linear-gradient(transparent, transparent, white)`,
     // backgroundColor:"tranparent",
     // backgroundRepeatY:'no-repeat',
      maxHeight:650,
      minHeight:400,
      width:"100%",
   resize:'both',
   //position:'absolute'
     
  } 

  const homebrgd = {
    backgroundImage:`url( "${ landingImage } ")`,
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    height:'100%',
    width:"100%",
 resize:'both',
 position:'fixed',
 top:80,
 zIndex:-3,
   
} 

  const boldText={
color:"#5D6D7E",
fontSize:10,
fontWeight:"bold"
  }

  const smallBoldText={
    color:"#707070",
    fontSize:15,
    fontWeight:"bold",
    paddingLeft:1,
    paddingRight:10,
    border:'none'
      }

      const smallText={
        color:"#707070",
        fontSize:15,
        fontWeight: 400,
        paddingLeft:10,
        paddingRight:10,
        border:'none'
          }

      const smallBlackBoldText={
        color:"black",
        fontSize:15,
        fontWeight:"bold",
        paddingLeft:10,
        paddingRight:10,
          }

  const classButton={
    backgroundColor:"transparent",
    color:"#363636",
    fontWeight:"bold",
    fontSize:15,
    borderWidth:0,
    maxWidth:250,
    minWidth:50,
    height:30,
    borderRadius:10,
    paddingLeft:20,
    paddingRight:20,
    //boxShadow: "2px 4px 10px #9E9E9E",
    
  }

  const centerButton={
    backgroundColor:"transparent",
    color:"white",
    fontWeight:"bold",
    borderWidth:1,
    borderColor:'white',
    border:'solid',
    fontSize:15,
    //borderWidth:0,
    maxWidth:250,
    minWidth:50,
    height:40,
    borderRadius:10,
    paddingLeft:20,
    paddingRight:20,
    boxShadow: "2px 4px 10px #9E9E9E",
    
  }

  const redText={
      fontWeight:400,
      color: '#DB0000',
      textDecoration: 'none'
      
  }

const Styles={
    home:home,
    homebrgd,
    redText,
    background,
    classButton:classButton,
    centerButton,
    boldText:boldText,
    smallBoldText:smallBoldText,
    smallText,
    smallBlackBoldText,
    inputFocus:inputFocus,
    input:input,
    input2,
   card: card,
   dateCard: dateCard,
   actionKey:actionKey, 
   textHeader:textHeader,
   button:button,
   disabledButton,
   wrapper:wrapper,
   label:label,
   labelwhite,
   centerWrapper:centerWrapper,
   articleWrapper,
   trigger:trigger,
   submenu,
   searchInput,
   override,
   authWrapper,
   container,
   producthWrapper,
   inputContainer,
   inline,
   error,
   framelessButton,
   flexContainer,
   pictureInput, 
   whiteBackgroundWrapper, 
   ashWrapper,
   ashWrapperColumn
}

export default Styles;