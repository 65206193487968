import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal, Form} from 'react-bootstrap';
import {useState} from 'react';
import Styles from '../styles/styles';

const CustomModal =(prop)=>{


    return(
        <div>
            <Modal show={prop.show} onHide={prop.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {prop.children}  
        </Modal.Body>


        <Modal.Footer>
          <Button variant="secondary" onClick={prop.handleClose}>
            Close
          </Button>
          {/*<Button style={Styles.button} variant="custom" onClick={prop.handleClose}>
            Save Changes
    </Button>*/}
        </Modal.Footer>
      </Modal>

        </div>
    )
}
export default  CustomModal;