import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Col, Container, Form, Modal, Row} from 'react-bootstrap';
import {useState} from 'react';
import Header from '../components/Header';
import ProductCard from '../components/ProductCard';
import CustomInput from '../components/CustomInput';
import Styles from '../styles/styles';
import useFetch from '../customHook/useFetch';
import fetchFunc from '../customHook/fetchFunc';
import axiosFetch from '../customHook/axiosFetch';
import url from './url';
import { useSelector , useDispatch} from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import useLoginStatus from '../customHook/useLoginStatus';
import cookie from 'react-cookies';
import ScaleLoader from "react-spinners/ScaleLoader";


const ForgotPasswordLink= ({lurl,redirect, status})=>{

const [password,setPassword]= useState("");
const [repeatPassword,setRepeatPassword]= useState("");
const [buttonState, setButtonState] = useState("Change Password");

//navigation
const navigate = useNavigate();

  //fetch info]from url
  let { userId, token } = useParams();
  //body to be sent
const body = {password, userId, token, request:"login"}

     const [loginError, setLoginError] = useState(null);
     const [passwordInput, setPasswordInput] = useState(true);
     const [messageSent, setMessageSent] = useState(null);
     const [reply, setReply] = useState(null);

     //if logged in redirect 
      if (status==="loggedIn"){
     
    navigate(redirect);
     }


    //set validation for password
    const send = async(e)=>{
        e.preventDefault();
        setButtonState("Loading");
        setLoginError(null)
    // setUser( axiosFetch("https://39f5-41-217-100-12.ngrok.io/admin/login/",email,password)) ;

    //check password match
    if (password !== "" && password === repeatPassword){  
      let  sendPassword = await fetchFunc(url(`auth/forgotpasswordlink/${userId}/${token}`),"POST",body,true)
    
      //if successful
     if (sendPassword && sendPassword.msg === "password reset sucessfully."){
//console.log(email.msg)
setPasswordInput(false)
        setMessageSent(true);
        setReply(sendPassword.msg)
     }

     //if unsuccessfull
if(sendPassword.msg !== "password reset sucessfully."){

setButtonState("Change Password")
   setLoginError(JSON.parse(sendPassword).msg)
    //setLoginError(email.msg)
  }
     }else{
         //if password doesnot match
        setLoginError("Passwords do not match")
setButtonState("Change Password")
};
    }

    return(
        <div>


   <div>
  <div style={Styles.container}>


       <form onSubmit={(e)=>{send(e)}}>
           <h3 style={Styles.textHeader}>Reset password</h3>
           <p>{loginError}</p>
     { passwordInput &&      <div style={Styles.authWrapper}>
         <div style={Styles.inputContainer}> 
         <label style={Styles.label}>New password</label> 
           <input style={Styles.input} type="password" placeholder="Enter new password" onChange={(e)=>{setPassword(e.currentTarget.value)}} /></div>
           <div style={Styles.inputContainer}>  
           <label style={Styles.label}>confirm new password</label> 
           <input style={Styles.input} type="password" placeholder="confirm new password"  onChange={(e)=>{setRepeatPassword(e.currentTarget.value)}} /></div>

           
         
             <div style={{margin:10}}>  
             <button style={Styles.button} type="submit" > { buttonState ==="Loading" && <ScaleLoader color={"#E92C2C"} loading={true} cssOverride={Styles.override} size={150} />} 
             { buttonState ==="Change Password" && buttonState} 
             </button></div></div>}

             {messageSent && <h5>{reply}</h5>}
             <Link to="/login" >Back to Login</Link>
             
</form>
</div>
</div>


       </div>
   );

}

export default ForgotPasswordLink;