//import './App.css';
//import'../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal} from 'react-bootstrap';
import {useState} from 'react';
import Header from '../components/Header';
import Styles from '../styles/styles';
import { Col,Row,Container,Accordion } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { useNavigate } from 'react-router-dom';
import JotformEmbed from 'react-jotform-embed';
import CustomModal from '../components/CustomModal';
import aboutpic from '../images/cprtaylor2.jpg'
import { IoBeaker, IoBody, IoBonfire, IoBookOutline, IoBusiness, IoCarOutline, IoCartOutline, IoCheckboxOutline, 
  IoCheckmarkCircleOutline, IoCheckmarkDoneCircle, IoChevronDown, IoDesktop, IoDiamond, 
  IoGlobeOutline, 
  IoMapOutline, 
  IoOptions, 
  IoTime,
  IoTimeOutline,
  IoTimeSharp} from 'react-icons/io5';
import DiscountObj from '../components/DiscountObj';
import InstructorObj from '../components/InstructorCourseObject';
import { IoIosMap, IoMdClock } from 'react-icons/io';


const Home=()=>{

  const navigate = useNavigate();
  
const [ITLSform, setITLSform] = useState(false)

const numberOfDiamond = (num)=>{
  for(let i = 0; i < num + 1; i++){
    return(
      <IoDiamond size={30} color='white'/>
    )
      
  }
}
    return(
      <div>
        <div style={Styles.home}>
          <img src={require('../images/cprtaylor2.jpg')} style={{position:'absolute', zIndex:-1, width:'100%', height:'70%', minHeight:400, maxHeight:650}}/>
         <Container style={{height:"100%", minHeight:300, maxHeight:650, display:'flex', alignItems:'center'}} className='d-flex justify-content-center align-items-center flex-column'>
         
          <Button className='' onClick={()=>navigate('/course_date')} style={Styles.centerButton}>Find Our Courses</Button>
         
           
           </Container> 
   
       </div>

       <div style={{backgroundColor:'white', display:'flex', justifyContent:'center', alignItems:'',
        flexWrap:'wrap', padding:10, paddingBottom:10}}>

<div style={{width:'100%', maxWidth:400, height:200, background:'transparent', 
borderRadius:0, margin:0, padding:0, display:'flex', flexDirection:'column', alignItems:'center', textAlign:'center'
}}>

  <IoCartOutline size={80} color='red'/>
  <h5 style={{fontFamily:'fantasy'}}>Products</h5>
  <p>Our products range from ambulance supplies to life support training supplies</p>
</div>

<div style={{width:'100%', maxWidth:400, height:200, background:'transparent', 
borderRadius:20, margin:5, padding:10, display:'flex', flexDirection:'column', alignItems:'center',  textAlign:'center'
}}>

  <IoBookOutline size={80} color='red'/>
  <h5 style={{fontFamily:'fantasy'}}>Courses</h5>
  <p>We Offer various medical and trauma life support accredited by relevant international organization. we incorprate the
    high tech simulations to aid learning.
  </p>
</div>

<div style={{width:'100%', maxWidth:400, height:200, background:'transparent', 
borderRadius:20, margin:5, padding:10, display:'flex', flexDirection:'column', alignItems:'center',  textAlign:'center'
}}>

  <IoCarOutline size={80} color='red'/>
  <h5 style={{fontFamily:'fantasy'}}>Ambulance Services</h5>
  <p>We offer ambulance services which include patient transfer and ambulance coverage. 
    our fleet include BLS and ALS ambulances</p>
</div>

          </div>

<div style={{backgroundColor:'white', display:'flex', justifyContent:'center', textAlign:'center',
        flexWrap:'wrap', padding:50, paddingBottom:20}}>
<h2>Become an AHA instructor</h2>
<p>The American Heart Association is always seeking professionals to conduct training 
  in first aid, CPR, AED, and advanced cardiovascular care. If you are passionate about 
  saving lives, motivated to facilitate learning, feel comfortable in group settings, and 
  find it easy to make complex concepts understandable to others, you may be a perfect candidate.</p>
</div>

        <div style={{backgroundColor:'white', display:'flex', justifyContent:'center', 
        flexWrap:'wrap', padding:10, paddingBottom:20}}>

          {
            InstructorObj.courses.map((course, index)=>{
              let total = 0
              let count = 0
              while(count< course.cost.length){
              total = total + course.cost[count].cost
              count++
              }
              return(
                <div key={index} style={{width:'100%', maxWidth:400, height:700, background:`linear-gradient(black, #E01212 ,#EB6A6A, transparent )`, 
borderRadius:20, margin:5, padding:10, display:'flex', flexDirection:'column', alignItems:'center',
}}>
  
    <div>{
      numberOfDiamond(index)
     }
        </div>
      
    
  <h3 style={{color:'white'}}>{course.title}</h3>
 
    
    <h6 style={{ color:'white', padding:0}}>{course.description}</h6>
    {/*<p style={{  color:'white', backgroundColor:'black', 
  padding:5, borderRadius:15}}>Discount</p>*/}
  

  <p style={{  color:'grey', backgroundColor:'white', width:'100%', textAlign:'center',
  padding:5, borderRadius:15}}>Criteria</p>
  <div style={{color:'GrayText',}}>
    {course.criterias.map((criteria, k)=>{


      return(
        <p key={k} style={{fontSize:15, color:'#CECECE'}}><IoCheckmarkDoneCircle size={30}/> {criteria}</p>
      )
    })}

    
    
  </div>
  <p style={{  color:'white', backgroundColor:'grey', width:'100%', textAlign:'center',
  padding:5, borderRadius:15}}>Course fee</p>

  <div style={{color:'GrayText',}}>
    { course.cost.map((itemCost, i)=>{
    
      return(
        <p key={i} style={{fontSize:15, color:'black' }}><IoCheckmarkDoneCircle size={30}/> {itemCost.title}: <b>N{itemCost.cost.toLocaleString()}</b></p>
      )
    })}

    <h2>Total:{total.toLocaleString()} </h2>
    
  </div>
  
  
</div>
              )
            })
          }
        
      </div>

  <div style={{backgroundColor:'white', display:'flex', justifyContent:'center', 
        flexWrap:'wrap', padding:10, paddingBottom:20}}>
  <button style={{backgroundColor: 'transparent', border:'hidden', fontStyle:'italic'}} 
  onClick={()=>{navigate('/instructorcandidateform')}}><h4><b>Apply here</b></h4></button> 
</div>


       <div style={Styles.homebrgd}>
       
       </div>

       {// not  neccessary
       
       /*<div style={{background:`linear-gradient(transparent, black)`, padding:30, height:150}}></div>*/}

    
      {/* <div style={{background:'white', width:'100%', padding:10, height:400,position:'relative' }}> 
       
       <img style={{width:'100%', height:'100%',  backgroundColor:'white', position:'relative', top:0}}src={require('../images/cprtaylor.jpg')} />
      <h4 style={{position:'absolute', left:'15%', bottom:10}}>CPR family pack</h4>
       <h4 style={{position:'absolute', left:10,  
       top:10, backgroundColor:'red', color:'white', width:70,
       paddingTop:20, paddingBottom:20, borderRadius:50}}>N1.5m</h4>
       {/*<button style={{
        position:'absolute', 
       bottom:0, boxShadow: "-0px 5px 7px #9E9E9E", border:'none',
        left:'45%', color:'white', fontStyle:'italic', fontWeight:'bold',
        backgroundColor:'black', padding:20, borderRadius:50, margin:30
        }}>Order now</button>
      </div>*

<div style={{background:`linear-gradient(transparent, black)`, padding:30, height:150}}>

</div>/}


       <button style={{zIndex:2, backgroundColor:'transparent', border:'none', position:'fixed', bottom:50}} onClick={()=>setITLSform(true)}>
        <img height={80} src={require('../images/request itls.png')} /></button> 
<CustomModal show={ITLSform} handleClose={()=>setITLSform(false)}>
  <div style={{width:'100%'}}>
  <JotformEmbed src="https://form.jotform.com/232295441427557" />
  <div style={{width:'94%', height:200,backgroundColor:'white', position:'absolute', bottom:20}}></div>
</div>
</CustomModal>


{/*
<div style={{paddingTop:10, backgroundColor:'white'}}>
 
<div style={{width:'100%', display:'flex', height: 255, backgroundImage:`url(${aboutpic})`, justifyContent:'flex-end', }}>

<div style={{
  width: '80%',
  height: 100,
  borderLeft: '90px solid transparent',
  borderRight: '0px solid transparent',
  borderBottom: '235px solid #910a00',
  position:'absolute',
  right:10,
zIndex:0,
  
 
}}></div>
  
  
  
  <div style={{
  width: '80%',
  height: 100,
  borderLeft: '100px solid transparent',
  borderRight: '0px solid transparent',
  borderBottom: '255px solid black',
  zIndex:1
  
 
}}><div style={{height:'100%', minHeight:240, padding:10,
justifyContent:'center',
alignItems:'center',}}>
  <h5 style={{paddingLeft:10, fontFamily:'fantasy', color:'white'}}>Life Support Courses and First Aid</h5>

  <div style={{height:'100%', minHeight:170, maxHeight:170,
justifyContent:'center',
alignItems:'center', overflowY:'scroll', scrollbarWidth:'none'}}>
  <p style={{color:'#B6B6B6', padding:10, fontSize:18}}>
  Our internationally accredited courses are designed 
to equip healthcare providers and first responders with life saving skills 
at various levels of care, including AHA courses and 
Trauma coursessuch as the ATLS and ITLS <a style={{color:'red'}}>read more..</a></p>
</div>
</div>
</div>


</div>
</div>*/}

<div style={{backgroundColor:'white', padding:20}}>

</div>




<div style={{width:'100%', maxHeight:200, backgroundColor:'black',  opacity:0.8, padding:10, paddingTop:10}}>
  <h5 style={{fontFamily:'fantasy', color:'white'}}>Our Clients</h5>
  <div style={{overflow:'scroll', display:'flex',  scrollbarWidth: 'none'}}>
  <img  style={{padding:10}} height={70} width={150} src={require('../images/totallogo.png')} />
  <img  style={{padding:10}} height={70} width={150} src={require('../images/mobillogo.png')} />
  <img style={{padding:10}} height={70} width={150} src={require('../images/lnglogo.png')} />
  <img style={{padding:10}} height={70} width={150} src={require('../images/NEMA.jpg')} />
  <img style={{padding:10}} height={70} width={150} src={require('../images/NA.jpg')} />
  </div>

</div>
      </div>
       
    
    );

}

export default Home;