import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal, Tabs, Tab} from 'react-bootstrap';
import {useState} from 'react';
import Header from '../components/Header';
import Styles from '../styles/styles';
import CustomInput from '../components/CustomInput';
import TripDetails from '../components/TripDetails';


const AmbulanceServices=()=>{

    return(
        <div style={Styles.wrapper}>
        <h3>Ambulance Calculator</h3>
        <p>
            The Ambulance calculator is a tool used to estimate the cost of our ambulance services in a bid to make ambulance services more affordable.
        </p>

        <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
  <Tab eventKey="intrastate" title="Within the State">
    <TripDetails coverage="intrastate"/>
  </Tab>
  <Tab eventKey="interstate" title="Interstate">
  <TripDetails coverage="interstate"/>
  </Tab>
  <Tab eventKey="contact" title="International" disabled>
    <Header />
  </Tab>
</Tabs>

        

        </div>
    );

}

export default AmbulanceServices;