import React,{useState,useEffect, useRef} from "react";
import SimpleImageSlider from "react-simple-image-slider";
import url from "./url";

const SliderImages =({images})=>{
const imgRef = useRef([])
    const [img, setImg] = useState(imgRef);
    const [imgReady, setImgReady] = useState(false);

   

useEffect(async()=>{

    for(let i = 0; i < images.length; i++){

 await fetch(url(`pictures/${images[i].filename}`), {
         
         method: "GET", 
         redirect: "follow",
           credentials: 'include',
       })
   .then((response)=>{
      // console.log(response)
       return response.blob()
   }).then((res)=>{
         // console.log(res)
         // const reader = new FileReader()
         // return reader.readAsDataURL(response)
         
      //   const url = URL.createObjectURL(res)
        // URL.revokeObjectURL(res)
       //  window.open(url)
        const url = URL.createObjectURL(res)
    return url;
        
       }).then((response)=>{
        imgRef.current = imgRef.current.concat(response)
        setImg(imgRef.current)
      //  console.log(img)
       }).then((response)=>{
        setImgReady(true);
       }).catch((err)=>{
           console.log(err)
       }); 
        
 }
},[])

   




    return(
        <div style={{
            minWidth:10,
            maxWidth:500,
            width:300,
            height:300,
            justifyContent:'center'}}>
                {console.log(img)}
            {imgReady &&   <SimpleImageSlider
        width={'inherit'}
        height={'inherit'}
        style={{}}
        images={img}
        showBullets={true}
        showNavs={true}
        
      />}
          </div>
    )
}

export default SliderImages;