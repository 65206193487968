import { useState} from "react";
import cookie from 'react-cookies';



const fetchFunc=(url,method,body)=>{

  //  const expires = new Date()
  //  expires.setDate(Date.now() + 1000 * 60 * 60 * 24 )
    const methdBody=(body)=>{
        if(method ==="POST"){
return JSON.stringify(body)
//return body;
        }else{return null}
    }

    
    //const cType= 'text/plain';

    return    fetch(url,
            {
                //mode:'cors',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: method,
            mode:'cors',
            redirect: "follow",
            credentials: 'include',
            body: methdBody(body)
        })
        .then(res=>{
            console.log(res)
            if(!res.ok){
          return res.text().then(text=> {throw Error(text)});
            }
            return res.json();
        })
        .then(data=>{

           console.log(data) 
           return data
            
        }).catch(err=>{
            console.log(JSON.parse(err.message).msg)
           
      return JSON.parse(err.message)
        
    })
   
    
}
export default fetchFunc;