import { useState, useEffect } from "react";



const useFetch=(url,method,body,trigger)=>{
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    const methdBody=(body)=>{
        if(method ==="POST"){
return JSON.stringify(body)
        }else{return null}
    }
   
    useEffect(()=>{
        fetch(url,
            {
               // mode:'cors',
             /*   headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json"
            },*/
            method: method,
            mode:'cors',
            credentials: 'include',
            redirect: "follow",
            body: methdBody(body)
        })
        .then(res=>{
          /*  if(!res.ok){
                throw Error('could not fetch resource');
            }*/
            return res.json();
        })
        .then(data=>{
            setData(data);
            setIsPending(false);
            setError(null);
            
        }).catch(err=>{
        setIsPending(false);
        setError(err.message);
    })
    },[trigger]);
    return {data, isPending, error};
}
export default useFetch;