import '../App.css';
//import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal, Form} from 'react-bootstrap';
import {useState} from 'react';
import CustomModal from './CustomModal';
import Registration from './Registration';
import Styles from '../styles/styles';
import Background from '../images/white2.jpg';
import { useSelector, useDispatch } from 'react-redux';
import AppLogin from '../pages/AppLogin';
import useFetch from '../customHook/useFetch';
import url from './url';
import {FaCertificate} from "react-icons/fa"
import numberToWords from '../functions/numberToWords';
import cookie from 'react-cookies';
import RegisterAsGuest from './RegisterAsGuest';


//this is a card showing dates
const Dates =({dateData,  user, status,  userId, setStatus, setAccount, setUser})=>{
//removed id cos of useState
    const [registration, setRegistration] = useState(false);
    const [redisterAsGuest, setRegisterAsGuest] = useState('none')
    const [loggedInRegistration, setLoggedInRegistration] = useState('block')
    const [switchButton, setSwitchButton] = useState("Continue as a guest")

    const switchRegMode = () =>{
      loggedInRegistration =='block'? setLoggedInRegistration('none'):setLoggedInRegistration('block')
      redisterAsGuest == 'none'? setRegisterAsGuest('block'): setRegisterAsGuest('none')
      switchButton == "Continue as a guest"? setSwitchButton("Login"):setSwitchButton("Continue as a guest")
    }
    const convertDate =(rawDate)=> new Date(rawDate).toDateString();
    //console.log(convertDate(date));
    const id = cookie.load('id');
    const courseRegistration = useSelector(state=>state.courseRegistration)
const trigger ="";
const{data, isPending, error} = useFetch(url(`user/${id}`),"get",null,trigger);
//create a useeffect that gets data of the user in the registration page
//console.log(data && data.status)



var  color ="#ECDCFF"
switch(dateData.course.code) {
    case "blsprovider":
       color ="#DCE0FF"
      break;
    case "aclsprovider":
        color ="#FFDCDC"
      break;
    default:
        color ="#ECDCFF"
  }
  
return(
<div  style={Styles.dateCard}>
    

{//<div className="card">
}
    {//<img src={Background}/>
}

{/*<div style={{backgroundColor: color, height:"100%", width:"100%", position:"absolute", 
zIndex:0, opacity:0.5, }}>
</div>*/}



    <div style={{ 
    padding:10, 
    borderTopLeftRadius:20, 
    borderTopRightRadius:20, 
    color:"#585858", 
    marginBottom:10, 
    marginTop: -15, 
    marginInline:-15, 
    xIndex:1, display:"flex", 
    flexWrap:'wrap', 
    backgroundColor: color
  }}  
    onClick={()=>window.alert(dateData.course.item.toUpperCase())}
    >
 <h6 className="card-title">{dateData.course.item.toUpperCase()}</h6>
 </div>
{/* <div style={{position:"relative", height:0, top:100, left:200 }}>
     <FaCertificate size={50} color={color}/>
</div>*/}
<h4>{convertDate(dateData.date)}</h4>
<p className="card-text">{dateData.mode}</p>
 <p style={Styles.smallBoldText}>{dateData.venue.location}</p>
 <h3>{numberToWords(dateData.course.value)}</h3>
 <div style={{position:'absolute', right:10, bottom:30}}>
 <Button className="action-key" style={Styles.button} variant="custom" onClick={()=>setRegistration(true)}>Enroll</Button>

</div>




<CustomModal show={registration} handleClose={()=>setRegistration(false)}>
    {isPending && <p>loading...</p>}
      {data && data.status === "loggedIn" &&<Registration dateData={dateData} date={convertDate(dateData.date)} /*address={address} 
      cost={product} ebook={ebook} data={data} id={id} */color={color} user={user} userId={userId}/>}
      {data && data.status !== "loggedIn" &&<div>
        <div  style={{display:loggedInRegistration}}><AppLogin redirect="/clientarea/course_date/"
       status={status} setStatus={setStatus} setAccount={setAccount} setUser={setUser}/></div>
       <div style={{display:redisterAsGuest}}><RegisterAsGuest dateData={dateData} date={convertDate(dateData.date)} color={color}/></div>
       <button onClick={()=>switchRegMode()} style={Styles.classButton}>{switchButton}</button>
       </div> }
</CustomModal>
</div>


);

}

export default Dates;
