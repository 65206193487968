
const numberToWords=(num)=>{
 
let number = num
let numStr = number.toString();
let length = numStr.lenght
let decimal = numStr.substr(-2,2)
let hundred = numStr.substr(-5, 3);
let thousand = numStr.substr(-8,3);
let million = numStr.substr(-12,3);
let billion = numStr.substr(-14,3);

let words=numStr;


switch(true) {

    case (number<1):
        words= decimal;
        break;
  case (number<1000):
    words= numStr;
    break;
    
  case (number<100000000 && number>100000):
  words= numStr.slice(0,-5)+ ',' +hundred+'.'+decimal;
    break;
    
   case (number<100000000000 && number>100000000):
  words= numStr.slice(0,-8)+ ',' +thousand+ ',' +hundred+'.'+decimal;
    break;
    
    case (number>=100000000000) :
  words= numStr.slice(0,-12)+','+million+ ',' +thousand+ ',' +hundred+'.'+decimal;
    break;
    
  default:
    words= numStr;
}


return '₦'+words ;




}

export default numberToWords;