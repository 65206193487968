import React, { useState } from "react";
import useFetch from '../customHook/useFetch';
import url from "../components/url";
import { Container,Row,Col,Table,Dropdown,Form } from "react-bootstrap";
import Styles from "../styles/styles";
import { ArrowRight, ThreeDots } from 'react-bootstrap-icons';
import CustomInput from "../components/CustomInput";
import { useLocation, useNavigate } from "react-router-dom";

const Candidates=()=>{

 
const Navigate = useNavigate();
const location = useLocation();
    const [trigger, setTrigger]= useState(true);
    const {data, isPending, error} = useFetch(url(`user/participantsbydates/${location.state.id}`),"GET", null, trigger);
    const Participants = data;
    console.log(location.state);
    console.log(Participants)
    //change corses to trainig date in front and backend
    return(
        <Container style={Styles.wrapper}>
          <h4>{location.state.course}</h4>
          <h5 style={Styles.boldText}>{location.state.date}</h5>
          <h5 style={Styles.smallBoldText}>{location.state.venue}</h5>
          <h5></h5>
            <h4>CANDIDATES</h4>
            <Form.Control type="text" placeholder="Search" style={Styles.searchInput}/>
            <button style={Styles.button} onClick={()=>{Navigate("/user/manual_register",{
          state:{
            course:location.state.course,
          date: new Date(location.state.date).toDateString(),
          venue: location.state.venue,
          id: location.state.id,
          }
            })}}>Add Candidate</button>
            <div class style={{width:"", overflowX:"scroll"}}>
          

      { Participants && Participants.map((participant, i)=>{

return(
  <div style={{width:"auto", display:"flex", flexWrap:"wrap", background:"white", borderRadius:10,
  margin:10, borderColor: "gray", borderStyle:"outset"}}  key={i}>
    <div style={{border: 'none',  borderWidth:0, padding:10, maxWidth:150}}>
      <h5>ID</h5>
      <p style={{border: 'none',  borderWidth:0}}>NIL</p>
    </div>
    
    <div style={{border: 'none',  borderWidth:0, padding:10, maxWidth:150}}>
      <h5>Firstname</h5>
      <p style={{border: 'none',  borderWidth:0}}>{participant.user.firstname}</p>
    </div>

    <div style={{border: 'none',  borderWidth:0, padding:10, maxWidth:150}}>
      <h5>Lastname</h5>
      <p style={{border: 'none',  borderWidth:0}}>{participant.user.lastname}</p>
    </div>

    <div style={{border: 'none',  borderWidth:0, padding:10,maxWidth:250}}>
      <h5>email</h5>
      <p style={{border: 'none',  borderWidth:0}}>{participant.user.email}</p>
    </div>

    <div style={{border: 'none',  borderWidth:0, padding:10,maxWidth:150}}>
      <h5>Phone</h5>
      <p style={{border: 'none',  borderWidth:0}}>{participant.user.phone}</p>
    </div>

    <div style={{border: 'none',  borderWidth:0, padding:10,maxWidth:150}}>
      <h5>Score</h5>
      <p style={{border: 'none',  borderWidth:0}}>NA</p>
    </div>

    <div style={{border: 'none',  borderWidth:0, padding:10,maxWidth:150}}>
      <h5>Status</h5>
      <p style={{border: 'none',  borderWidth:0}}>NA</p>
    </div>

    <div style={{border: 'none',  borderWidth:0, padding:10,maxWidth:150}}>
      <h5>Certifricate</h5>
      <p style={{border: 'none',  borderWidth:0}}>NA</p>
    </div>

    <div>
      <h5></h5>
      <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-basic"
      style={{border:"none", maxHeight:30,boxShadow:"none"}} 
      className="btn btn-xs">
      <ThreeDots color="royalblue" size={20}/>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={()=>{Navigate('/user/candidates',{date:"man dates"})}}>View</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Assign Cert</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Suspend</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    </div>
    
   </div>

  
)


      })
    }

      
       
      </div>
        </Container>
    )
}

export default Candidates;