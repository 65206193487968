import React from "react";
import Styles from "../styles/styles";
import { useState } from "react";
import {useNavigate, useLocation} from'react-router-dom';
import fetchFunc from "../customHook/fetchFunc";
import url from "../components/url";



const ManualRegister =()=>{
    const location = useLocation();
    const Navigate = useNavigate();
    const [firstname, setFirstname]= useState("");
const [lastname, setLastname] = useState("");
const [phone, setPhone] = useState("");    
const [email,setEmail]= useState("");
const [training_date, setTraining_date] =useState(location.state.id);
const [buttonState, setButtonState] = useState("Create Account");
const[errorMsg, setErrorMsg] = useState("");
const[linkSent, setLinkSent] = useState(false);
const[verificationmsg, setVerificationmsg] = useState("");
const[dataInput, setDataInput] = useState(true);
const[sendPaymentLink, setSendPaymentLink] = useState(false);
const body ={firstname,lastname,phone,email, training_date,sendPaymentLink };

const [fetchError, setFetchError]= useState("")
const send = async(e)=>{
    e.preventDefault();
    setErrorMsg("")
    setButtonState("Loading");
// setUser( axiosFetch("https://39f5-41-217-100-12.ngrok.io/admin/login/",email,password)) ;

 let userID = await fetchFunc(url("user/manualaddparticipant"),"POST",body,true);

 //dispatch(actionFunc({userID: await userID.user}));
// dispatch(actionFunc({firstname: await userID.firstname}));
// dispatch(actionFunc({status: "loggedIn"}));
 
 if(userID && userID.msg === "Successful Registration"){
    //setTrigger(!trigger) // does not appear on history
    setLinkSent(userID.msg)
    window.alert(userID.msg);
    Navigate('/user/dates');

    //navigate("/")
// window.location.reload(); 
 }
if(userID && userID.msg !== "Successful Registration"){
setButtonState("Enroll")
console.log(userID);
setFetchError(userID.msg)}
 


}


    return(
<div style={Styles.centerWrapper}>
    <h6>ADD CANDIDATE</h6>
    <h5>{location.state.course}</h5>
    <p style={Styles.smallBoldText}>{location.state.date}</p>
    <p style={Styles.smallBoldText}>{location.state.venue}</p>
    <p style={Styles.smallBoldText}>Manually add a candidate</p>
    <form onSubmit={(e)=>{send(e)}}>
        <div style={{display:"flex", flexWrap:"wrap", padding:10}}>
        <div>
    <label style={Styles.smallBoldText}>First name</label>
    <input placeholder="First name" style={Styles.input2}
    onChange={(e)=>{setFirstname(e.currentTarget.value)}}
    />
    </div>
    <div>
    <label style={Styles.smallBoldText}>Last name</label>
    <input placeholder="Last name" style={Styles.input2}
    onChange={(e)=>{setLastname(e.currentTarget.value)}}
    />
    </div>
    </div>
    <div style={{display:"flex", flexWrap:"wrap", padding:10}}>
    <div>
    <label style={Styles.smallBoldText}>Email</label>
    <input placeholder="Email" style={Styles.input2}
    onChange={(e)=>{setEmail(e.currentTarget.value)}}
    />
    </div>
    <div>
    <label style={Styles.smallBoldText}>Phone</label>
    <input type="number" placeholder="Phone number" style={Styles.input2}
    onChange={(e)=>{setPhone(e.currentTarget.value)}}
    />
    </div></div>
    <p style={Styles.smallBoldText}>Send payment link to the candidate  
    <input onChange={(e)=>{
        sendPaymentLink===false? setSendPaymentLink(true ):setSendPaymentLink(false);
    console.log(sendPaymentLink)}} type={'checkbox'}/></p>
    <button type="submit" style={Styles.button}>Enroll</button>
    </form>
</div>
    )
}
export  default ManualRegister;