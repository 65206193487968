import { useState } from "react";
import cookie from "react-cookies";
import useFetch from "../customHook/useFetch";
import Styles from "../styles/styles";
import Course from "./Course";
import Infobox from "./Infobox";
import url from "./url";




const UserCourses = ()=>{


    const convertDate =(rawDate)=> new Date(rawDate).toDateString();

    const token = cookie.load('id');
    //console.log(token);
    //console.log(access);
    const [trigger, setTrigger] = useState(false);
    
    const{data, isPending, error} = useFetch(url(`user/usercourses/${token}`),"GET",null,trigger);
data && console.log(data)
const addDate=(date)=>{
    console.log(new Date().setTime())
return new Date(date)
}
    return(
        <div>
            <h2>My Courses</h2>
       { data &&  data.map((courseInfo)=>{

        if(courseInfo.training_date){
       return(<Infobox  title={ courseInfo.training_date.course.item} backgroundColor="#00145F"
        infoArray={[{
        title:"date", 
        value: convertDate(courseInfo.training_date.date), 
        fontColor:"#707070"},
    
        {
            title:"status", 
            value: courseInfo.training_date.status, 
            fontColor:"#707070"},

            {
                title:"date", 
                value:new Date(courseInfo.training_date.date).toDateString(), 
                fontColor:"#707070"},

                {
                    title:"expiry", 
                    value: addDate(courseInfo.training_date.date).toDateString(), 
                    fontColor:"#707070"}
        ]}
        link={{title:"Details", value:""}}
        />) }
       }) 
          }
{data && data.length==0 && <h6>No course found.</h6>
}

      </div>
    )
}

export default UserCourses;