import '../App.css';
import'../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Button, Col, Container, Form, Modal, Row} from 'react-bootstrap';
import {useState} from 'react';
import Header from '../components/Header';
import ProductCard from '../components/ProductCard';
import CustomInput from '../components/CustomInput';
import Styles from '../styles/styles';
import useFetch from '../customHook/useFetch';
import fetchFunc from '../customHook/fetchFunc';
import axiosFetch from '../customHook/axiosFetch';
import url from './url';
import { useSelector , useDispatch} from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useLoginStatus from '../customHook/useLoginStatus';
import cookie from 'react-cookies';
import ScaleLoader from "react-spinners/ScaleLoader";
import {AiOutlineMail} from "react-icons/ai";


const ForgotPassword= ({lurl,redirect, status})=>{

const [email,setEmail]= useState("");
const [buttonState, setButtonState] = useState("Reset Password");

//navigation
const navigate = useNavigate();

  //fetch info

const body = {email, request:"login"}

     const [loginError, setLoginError] = useState(null);
     const [passwordInput, setPasswordInput] = useState(true);
     const [messageSent, setMessageSent] = useState(null);
     const [reply, setReply] = useState(null);

    const token = cookie.load('id');
 
    const [trigger, setTrigger] = useState(false);
      
      if (status==="loggedIn"){
     
    navigate(redirect);
     }


    
    const send = async(e)=>{
        e.preventDefault();
        setButtonState("Loading");
        setLoginError(null)
    // setUser( axiosFetch("https://39f5-41-217-100-12.ngrok.io/admin/login/",email,password)) ;
     let email = await fetchFunc(url("auth/forgotpassword"),"POST",body,true);
     
     if (email && email.msg === "A reset link has been sent to you"){
console.log(email.msg+"  hello")
setPasswordInput(false)
        setMessageSent(true);
        setReply(email.msg)
     }
if(!email.msg !== "A reset link has been sent to you"){

setButtonState("Reset Password")
   setLoginError(email.msg)
    //setLoginError(email.msg)
  }
     
    }

    

    return(
         <Container className='d-flex justify-content-center'>


    <div>
   <div style={Styles.centerWrapper}>


        <form onSubmit={(e)=>{send(e)}}>
            <h3 style={Styles.textHeader}>Forgot password</h3>
            <p>{loginError}</p>
      { passwordInput &&      <div>
          <div style={Styles.inputContainer}>  
          <label style={Styles.label}>Email</label>
          <div style={Styles.inline}><AiOutlineMail size={30} color={"#A09E9E"}/>
            <input style={Styles.input} type="email" placeholder="Enter email" onChange={(e)=>{setEmail(e.currentTarget.value)}} /></div>
            </div>
          
              <div style={{margin:10}}>  
              <button style={Styles.button} type="submit" > { buttonState ==="Loading" && <ScaleLoader color={"#E92C2C"} loading={true} cssOverride={Styles.override} size={150} />} 
              { buttonState ==="Reset Password" && buttonState} 
              </button></div></div>}

              {messageSent && <h5>{reply}</h5>}
              <Link to="/login" >Back to Login</Link>
              
</form>
</div>
</div>


        </Container>
    );

}

export default ForgotPassword;