import React from "react";
import Styles from "../styles/styles";
import CustomModal from "./CustomModal";
import { useState } from "react";
import ProductDetails from "./ProductDetail";
import numberToWords from "../functions/numberToWords";



const TrainingEquip =({account})=>{
const [show,setShow]=useState(false)
const [content,setContent]=useState(1)
let  products = [
    {id:1, item:"Manual Difibrillator", price:3000000, location:"Within Lagos", img:require('../images/marquette.jpeg'),engage:"Hire"},
    {id:2, item:"Adult CPR manikin", price:500000, location:"Within Lagos", img:require('../images/cprmanikin.jpg'),engage:"Hire"},
    {id:3, item:"Adult CPR manikin", price:500000, location:"Within Lagos", img:require('../images/cprmanikin.jpg'),engage:"Hire"}
    
]
    return(
        <div style={Styles.flexContainer}>
            
{products.map((product,i)=>{
    
    return(
        <div key={i} style={Styles.producthWrapper}>


    <div  style={{
        width:'100%',
        position:"relative"}}>

            <img height={200} width={250} src={product.img}/>
            <p style={Styles.boldText}>{product.item}</p>
    <p style={Styles.smallBoldText}>{numberToWords(product.price)}/12hrs</p>
    <p style={Styles.label}>{product.location}</p>
    
    <div style={{position:'absolute', right:10, bottom:20}}>
 <button className="action-key" 
 style={Styles.button} variant="custom" 
 onClick={()=>{
    setShow(true)
    setContent(i)
    }}>Hire</button>

</div>
    </div>
    
    
</div>

    )
})}


<CustomModal show={show} handleClose={()=>setShow(false)}>

<ProductDetails item={products[content].item} img={products[content].img} 
price={products[content].price} location={products[content].location} engage={products[content].engage}/>
</CustomModal>
</div>
        
    )
}

export default TrainingEquip;