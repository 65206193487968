import '../App.css';
import logo from '../logo.png';
import {useContext, useState} from 'react';
import { useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from 'rc-footer';
import 'rc-footer/assets/index.css'; // import 'rc-footer/asssets/index.less';
import { hover } from '@testing-library/user-event/dist/hover';
import slogo from '../images/hrtlogo.png';
import {BsFillGeoFill, BsFillTelephoneFill} from 'react-icons/bs';
import {IoMdMenu} from 'react-icons/io';
import {IoCartOutline, IoCloseCircle, IoPersonCircle, IoPersonCircleOutline} from 'react-icons/io5';
import useFetch from '../customHook/useFetch';
import url from './url';
import cookie from 'react-cookies';
import '../Menu.css';
import CustomModal from './CustomModal';
import Styles from '../styles/styles';
import Cart from '../components/Cart';
import CartButton from './CartButton';
import { useCookies } from 'react-cookie';
import AppContext from '../context/AppContext';


const Header =({})=>{
  
const {cookies,user, status, setStatus, setUser, setAccount} = useContext(AppContext)

//console.log(access);
const [trigger, setTrigger] = useState(false);
const [shoModal, setShowModal] = useState(false);
const [matches, setMatches] = useState(
  window.matchMedia("(min-width: 768px)").matches
)

const [showCart,setShowCart]=useState('none')

useEffect(() => { 
  console.log('Status:' +status)
 window
 .matchMedia("(min-width: 768px)")
 .addEventListener('change', e => setMatches( e.matches ));
}, []);

const menu =<div className='menu'>

<Link className="link" to="/" onClick={()=>{setShowModal(false)}}><p className='menuItem'>Home</p></Link>
<div className='dropdown'>
<p className='menuItem'>Courses</p>
<div className='dropdown-content'>
<Link className='link' to="/course_date" onClick={()=>{setShowModal(false)}}><p className='menuItem'> Enroll </p></Link>
<Link className='link' to="/bls" onClick={()=>{setShowModal(false)}}><p className='menuItem'>Basic Life Support</p></Link>
<a className='link' href='https://ersnigeria.com/courses/acls'onClick={()=>{setShowModal(false)}}><p className='menuItem'>Advanced Cardiovascular Life Support</p></a>
<a className='link' href='https://ersnigeria.com/courses/pals'onClick={()=>{setShowModal(false)}}><p className='menuItem'>Pediatric Advanced Life Support</p></a>
<a className='link' href=''><p className='menuItem'>International Trauma Life Support</p></a>
<a className='link' href=''><p className='menuItem'>Advanced Trauma Life Support</p></a>
</div>
</div>

<Link className='link' to="/ambulance"><p className='menuItem'>Ambulance Services</p></Link>
<Link className="link" ><p className='menuItem'>Products</p></Link>
    {//  <Link className="link" to="/faq"><p className='menuItem'>FAQ</p></Link>
}



</div>


  


    return(
   <>

<div >
 <div style={{display:'flex', fontWeight:'bold',  padding:5, justifyContent:'space-between', backgroundColor:'white'}}>
  {!matches && (<div style={{padding:10, }}><IoMdMenu size={35} color='#DB0000 ' onClick={()=>setShowModal(true)}/></div>)}
  <div style={{display:'flex', justifyContent:'center', width:"100%", maxWidth:150}}><img src={logo} height="35"/></div>
{matches && (menu)}
  {!matches && ( <CustomModal show={shoModal} handleClose={()=>setShowModal(false)} >
    {menu}
</CustomModal>)}
 

  
  <div  style={{position:"relative", right:0, display:'inline-flex', 
  justifyContent:"space-between",
   maxWidth:140,
  minWidth:80}}
   >
   
  <CartButton showCart={showCart} setShowCart={setShowCart} status={status}/>

 {status === "loggedIn" && <Link style={Styles.redText} 
 to="/user/dashboard"> <IoPersonCircle size={30}/>
 {/*user*/}</Link>}

      {user === "Guest" &&  <Link style={Styles.smallText} to="login">
        <IoPersonCircleOutline size={30}/>Login</Link>}

    

 </div>
</div>
<div style={Styles.background}>
<Outlet/>
</div>
<footer style={{backgroundColor:'white'}}>
 <Footer
 theme='dark'
    columns={[
      {
        icon: (
          <img src={slogo} />
        ),
        title: 'Emergency Response Services',
        url: 'https://ersnigeria.com',
        description: 'We are an organization located in Nigeria, West Africa with oprations centered around optimizing pre hospital and in facility care from medical and traumatic emergencies',
        openExternal: true,
        items:[
            {
                title:'welcome page',
                url: 'https://ersnigeria.com/clientarea/',
            },
            {
                title:'Find courses',
                url: 'https://ersnigeria.com/course_date/',
                description: 'Enroll for our emergency care courses',
            }
        ]
      },
      {
        icon: (
            <BsFillTelephoneFill /> 
          ),
          title:'Contact',
          items:[
            {
                title:'email us',
                url: 'info@ersnigeria.com',
                description: 'info@ersnigeria.com',
            },
            {
                title:'call us',
                url: '',
                description: '07065515064',
            }
        ]
      },
      {
        icon: (
            <BsFillGeoFill /> 
          ),
          title:'Address',
          items:[
            {
                title:'Rivers State',
                url: 'Off 394 Ikwerre Road, Rumuokuta',
                description: 'Off 394 Ikwerre Road, Rumuokuta, Port Harcourt',
            },
            {
                title:'Lagos',
                url: '',
                description: '6 Adeshina Street, Allen Avenue, Ikeja',
            }
        ]
      }
    ]}
    bottom="Emergency Response Services"
  /></footer>
  </div>
</>

    );

}

export default Header;