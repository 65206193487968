import Styles from "../styles/styles";
import { Link, useNavigate } from "react-router-dom";



const Infobox = ({backgroundColor,infoArray, title, link})=>{

    const Navigate = useNavigate();

    return(
      
        <div className="card" style={Styles.card}>
            <div style={{ backgroundColor: backgroundColor, position:"absolute", width:"100%", 
            height:"100%",opacity:0.9, zIndex:-1}}></div>
            <div style={Styles.wrapper}>
            <h3>{title}</h3>
            {infoArray.map((info,i)=>{

                return(
                   <p key={i} style={{color:info.fontColor}}><b>{info.title}:</b> {info.value}</p> 
                )
            })
        }

        <Link to={link.value}>{link.title}</Link>
           
            </div>
      </div>
     
    )
}

export default Infobox;