import React, { useEffect, useRef } from "react";
import Styles from "../styles/styles";
import CustomModal from "./CustomModal";
import { useState } from "react";
import ProductDetails from "./ProductDetail";
import numberToWords from "../functions/numberToWords";
import useFetch from '../customHook/useFetch';
import url from '../components/url';
import StreamedImage from '../components/StreamedImage';
import { useDispatch,useSelector } from "react-redux";
import fetchFunc from "../customHook/fetchFunc";
import cookie from 'react-cookies';
import AddToCart from "./AddToCart";
import { ExclamationLg } from "react-bootstrap-icons";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Container } from 'react-bootstrap';




const ProductCard =({account,status})=>{
const [show,setShow]=useState(false)
const [content,setContent]=useState(0)
const [trigger, setTrigger] =useState(false);


const {data, isPending, error} = useFetch(url(`products`),"GET", null, trigger);

//data && console.log(data)

    return(
        <div style={Styles.flexContainer}>
            { //make this a loader component
    isPending && <div style={{margin:20, minHeight:200}}>
       
    <Container style={{height:"100%"}} className='d-flex justify-content-center align-items-center flex-column'>
   
    <ScaleLoader color={"#E92C2C"} loading={true} cssOverride={Styles.override} size={150} />
   
    
    </Container> 
        
        </div>}
{data && !data[content] && <p style={Styles.smallBlackBoldText}><ExclamationLg size={20}/>No Product found</p>}
{data && data.map((product,i)=>{
   
    return(
        <div key={i} style={Styles.producthWrapper}  >


    <div  style={{
        width:'100%',
        height:350,
        position:"relative"}}>
<div style={{height:250}}
onClick={()=>{
            setShow(true)
            setContent(i)
            }}>{product.images[0] && <StreamedImage name={product.images[0].filename}/>}</div>
            
            <p style={Styles.redText}>{product.item}</p>
    <p style={Styles.smallBoldText}>{numberToWords(product.value)}</p>
    <p style={Styles.label}>{//product.location
    }</p>
    
    <div style={{position:'absolute', right:0, bottom:20}}>
 
  <AddToCart id= {product._id} quantity={1} status={status}/>
     

</div>
    </div>
    
    
</div>

    )
})}


<CustomModal show={show} handleClose={()=>setShow(false)}>

{data && data[content]  && <ProductDetails id={data[content]._id} item={data[content].item} images={data[content].images} 
price={data[content].value} location={data[content].location} transact={data[content].transact} description={data[content].description}/>}
</CustomModal>
</div>
        
    )
}

export default ProductCard;