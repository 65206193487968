import React, { useContext, useState } from "react";
import cookie from "react-cookies";
import { IoCart, IoCartOutline, IoCloseCircle } from "react-icons/io5";
import { useSelector } from "react-redux";
import useFetch from "../customHook/useFetch";
import Styles from "../styles/styles";
import Cart from "./Cart";
import url from "./url";
import AppContext from "../context/AppContext";



const CartButton =({showCart, setShowCart, status})=>{
   const {cookies} = useContext(AppContext)
    const trigger = useSelector(state=>state.trigger)
    const user = cookie.load('id');
    //duplicate fetch in cart component, please correct
    const{data, isPending, error} = useFetch(url(`user/cart/${cookie.id}`),'GET', "",trigger)
    let number = 0
 //dispatch display to close it globally
 data && console.log(data)
    return(
        <div style={{position:'relative'}}>
        <button style={Styles.framelessButton}  onClick={()=>{if(showCart ==="none"){ setShowCart('block')}else{setShowCart('none')}}}
     ><IoCart size={30} color='#6C6C6C'/>
     <div style={{
        height:16,
        minWidth:16,
        paddingTop:10,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position:'absolute',
        top:0, 
        right:5,
      backgroundColor:'#FFC300',
      borderRadius:25
      }}>
        <p>{data && data.length}{!data && number }</p></div></button>
     <div style={{maxWidth:400,width:'auto', height:'auto', backgroundColor:'white', 
     padding:10, position:'absolute', right:-10, top:40, display:showCart,
     color:'#616161',boxShadow: "-0px 5px 7px #616161", zIndex:2,
     }}><Cart status={status} cart={data}/>
      <div style={{position:'absolute', bottom:-60, left:'40%', zIndex:4,   zIndex:2}}
      onClick={()=>{if(showCart ==="block"){ setShowCart('none')}}}
      ><IoCloseCircle color='#8F0000' opacity={0.9} size={50}/></div>
     </div></div>
    )
}

export default CartButton;