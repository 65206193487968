import React from "react";
import Styles from "../styles/styles";
import { useNavigate } from "react-router-dom";
import TrainingEquip from "../components/TrainingEquip";

const SiteEquip = ({account})=>{
const navigate = useNavigate();
if(account === 'instructor'){
    return(
        <div style={Styles.container}>
 

    <div style={Styles.inline}>
        
            <TrainingEquip account={account}/>
        
    </div>


        </div>
    )
}
else{
    navigate('/');
    return(
        <div style={Styles.background} >
            <p style={Styles.boldText}>Not found</p>
        </div>
    )
}
}
export default SiteEquip;